@import url('https://use.typekit.net/tkn6hpc.css');
@import url('https://fonts.googleapis.com/css?family=Monoton');
.montserrat { font-family: "montserrat",sans-serif; }
.montserrat-alternates { font-family: "montserrat-alternates",sans-serif; }
:root{
  --text-primary: #fcfcfc;
  --text-secondary: rgb(255, 255, 255, 0.8);
  --glassButton-primary: #1D1D1D96;
  --bgPrimary: #000000;
  --bgSecondary: #ffffff;
  --blackPrimary: #000000;
  --bgPrimary-glass: rgb(0, 0, 0, 0.4);
  --MainColour: #ffffff;
  --PrimaryBlue: #006ee6;
  --PrimaryRed: #cf4141;
  --PrimaryGreen: #2ab95e;
  --secondaryColour: #ffffff;
  --accentColour1: #ffb866;
  --accentColour2: #ffffff;
  --border: #d9d9d9;
  --fontSecondary: #f8f8ff;
  --frostMenu: #1E1E1E00;

  /* DESKTOP SIZES */
  --fontDesktopH1: 3.5rem;
  --fontDesktopH2: 2.5rem;
  --fontDesktopH3: 2rem;
  --fontDesktopH4: 1.5rem;
  --fontDesktopP: 1.25rem;
  --fontDesktopPSmall: 1rem;

  /* MOBILE SIZES */
  --fontMobileH1: 1.563rem;
  --fontMobileH2: 1.25rem;
  --fontMobileP: 0.875rem;
  --fontMobileSecondary: 0.625rem;
  --fontMobile-Weight-Normal: 400;
  --fontMobile-Weight-Semibold: 600;
  --fontMobile-Weight-Bold: 700;
  --fontMobile-Button-Text: 1rem;

  /* PRELOAD */
  --preload-anim: 3s !important;
  --preload-anim-logo: 3s !important;
  /* SLABS */
  --slab-width: 37.5rem;
  --slab-height: 13.125rem;
  --slab-project-padding: 10px;
  --slab-project-border-radius: 21px;
  --slab-project-width: 37.5rem;
  --slab-project-height: 13.125rem;
  --slab-scale-down: 0.9;
  --slab-project-margin: 4px;

  /* ANIM TIMINGS */
  --anim-time-content-slab-enter: 1.5s;
  --anim-time-content-slab-exit: 1.5s;
  --slab-margin-desktop: 9px;
  --slab-margin-mobile: 2px;
}
html{
  scroll-behavior: auto;
}
html *,
body * {
  -webkit-tap-highlight-color: transparent !important;
  scrollbar-width: none !important; /* for Firefox */
  font-family: Montserrat, sans-serif;
  -ms-overflow-style: none; /* IE and Edge */
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: antialiased !important;
  text-rendering: auto !important;
}
html::-webkit-scrollbar {
  display: none;
}
a, Link, button{
  cursor: pointer;
}
::selection {
  background: rgba(140, 162, 211, 0.77); /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: rgba(140, 162, 211, 0.77); /* WebKit/Blink Browsers */
}
@media (max-width: 480px) {
    html{
      -webkit-overflow-scrolling: touch;
      overflow-scrolling: touch;
    }
  }
h1, h2, p{
  color: var(--text-primary);
  font-family: Montserrat, sans-serif !important;
}
.App {
  display: flex;
  -ms-overflow-style: none; /* IE and Edge */
  font-family: Montserrat, Roboto, sans-serif;
  background: transparent;
}
.pre-loader{
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  background-color: #000000;
  z-index: 9999;
  justify-content: center;
  align-items: center;
  animation: forwards fade-out var(--preload-anim);
  -webkit-animation: forwards fade-out var(--preload-anim);
  user-select: none;
}
.pre-loader-icon{
  position: fixed;
  top: 45vh;
  width: 300px;
  height: auto;
  animation: breathing var(--preload-anim) forwards linear;
  -webkit-animation: breathing var(--preload-anim) forwards linear;
}
.no-anim{
  animation: none !important;
  -webkit-animation: none !important;
}
.content-visibility-visible{
  content-visibility: visible;
}
.content-visibility-hidden{
  content-visibility: hidden;
}
.spinner button{
  font-size: 3.5rem;
  color: var(--MainColour);
  width: 100px;
    height: 100px;
  background-color: transparent;
  border: none;
}
.spinner button:hover{
}
.no-decoration{
  font-style: normal !important;
  opacity: 1;
}
.sub-hero-logo-text{
  font-weight: 400 !important;
  font-style: normal !important;
  opacity: 1;
}
@media (max-width: 480px) {
  .pre-loader-icon{
    width: 200px;
    top: 45vh !important;
  }
}
.apple-blue{
  background: white; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #b2fefa, #0e9ef7); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #b2fefa, #0e9ef7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.apple-blue-reverse{
  background: white; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #0e9ef7, #b2fefa); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0e9ef7, #b2fefa); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.instagram-grad{
  background: white; /* fallback for old browsers */
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.tint-image-10 img{
  filter: brightness(95%) !important;
  -webkit-filter: brightness(95%) !important;
}
.greyscale-image img{
  filter: grayscale(100%) brightness(80%);
  -webkit-filter: grayscale(100%) brightness(80%);
}
.text-overlay-bg-video{
  font-weight: 900 !important;
  color: white !important;
}
.polaris-text-2{
  font-family: Monoton, Montserrat, serif;;
  font-weight: normal;
  font-size: 2rem;
}
.subscript-ext-2{
  font-size: 0.7rem;
  top: -9px;
  position: relative;
  vertical-align: bottom;
  padding-left: 2px;
}
@media(max-width: 1400px) {
  .polaris-text-2{
    font-size: 1.5rem !important;
  }
  .subscript-ext-2{
    top: -6px;
  }
}
@media(max-width: 1200px) {
  .polaris-text-2{
    font-size: 1.4rem !important;
  }
  .subscript-ext-2{
    top: -5px;
  }
}
@media(max-width: 600px) {
  .polaris-text-2{
    padding-top: 0.5rem !important;
    font-size: 1rem !important;
  }
  .subscript-ext-2{
    top: -2.5px;
  }
}
@media(max-width: 420px){
  .polaris-text-2{
    font-size: 1rem !important;
  }
  .subscript-ext-2{
    top: -2px;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(-0px - 200vh));
    display: none;
  }
}
@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(-0px - 200vh));
    display: none;
  }
}
@keyframes breathing {
  0% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes breathing {
  0% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@media (max-width: 3440px) and (max-height: 1440px) {
  .slider {
    bottom: 60% !important;
  }
}
@media (max-width: 2560px) and (min-height: 1300px){
  .slider {
    bottom: 90% !important;
  }
}
@media (max-width: 1920px) and (max-height: 1080px) {
    .slider {
      bottom: 60% !important;
    }
}
@media (max-width: 550px) {
  .slider a.nextButton {
    opacity: 0 !important;
  }
}
@media (max-width: 480px) {
  .slider {
    position: absolute !important;
    bottom: 35% !important;
  }
}

/* CSS for the modal container */
.modal-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.7); /* Dark background */
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  background-color: #222; /* Dark color for modal content */
  padding: 20px;
  border-radius: 5px;
  max-width: 90%; /* Maximum width of modal content */
  max-height: 90%; /* Maximum height of modal content */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds modal height */
  position: relative; /* Required for positioning of the button */
}
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff; /* White color for close button */
  cursor: pointer;
}
.modal-title {
  color: #fff; /* White color for modal title */
  margin-top: 0;
}
.modal-text {
  color: #ddd; /* Light gray color for modal text */
  margin-bottom: 0;
}
.modal-okay {
  margin-top: 20px;
  background-color: #007bff; /* Blue color for okay button */
  color: #fff; /* White color for okay button text */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
@media (max-width: 768px) {
  /* Adjust modal styles for smaller screens */
  .modal-content {
    max-width: 95%;
    max-height: 95%;
  }
}

/* ANIMATIONS */
.swipe-anim-projects{
  position: absolute;
  top: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  padding: 0;
  margin: 0;
  background: transparent;
  border-radius: 13px;
  z-index: 1;
  transition: all 0.5s ease;
}
.display-none-swipe{
  position: absolute;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  opacity: 0;
  background: transparent;
  pointer-events: none;
  border-radius: 13px;
  transition: all 0.5s ease;
}
@keyframes fade-out-display-none {
  0% {
    opacity: 1;
  }
  60%{
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none !important;
    }
}
@-webkit-keyframes fade-out-display-none {
  0% {
     opacity: 1;
   }
  60%{
    opacity: 1;
  }
  100% {
     opacity: 0;
     display: none !important;
  }
}
.display-none{
  display: none;
  opacity: 0;
}
.perfect-box-shadow{
  box-shadow:
          0 1px 1px hsl(0deg 0% 0% / 0.075),
          0 2px 2px hsl(0deg 0% 0% / 0.075),
          0 4px 4px hsl(0deg 0% 0% / 0.075),
          0 8px 8px hsl(0deg 0% 0% / 0.075),
          0 16px 16px hsl(0deg 0% 0% / 0.075) !important;
}
.generic-fade-out{
  animation: generic-fade-out var(--anim-time-content-slab-exit) forwards;
  -webkit-animation: generic-fade-out var(--anim-time-content-slab-exit) forwards;
}
.generic-fade-in{
  animation: generic-fade-in var(--anim-time-content-slab-enter) forwards;
  -webkit-animation: generic-fade-in var(--anim-time-content-slab-enter) forwards;
}
.bg-blur{
  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
}
.bg-blur-darker{
  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
  background-color: rgba(10, 10, 10, 0.3) !important;
}
.bg-blur-remove{
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
}
.bg-transparent{
  background-color: var(--bgPrimary-glass);
}
.opacity-50{
  opacity: 0.33 !important;
}
.button-text-row p{
  font-size: 2rem;
  font-weight: 700;
  color: var(--text-primary);
  opacity: 1;
}
.button-text-row{
  font-size: 2.4rem;
  color: var(--text-primary);
  opacity: 1;
}
.red-filter{
    filter: sepia(100%) hue-rotate(320deg) saturate(900%)!important;
    -webkit-filter:  sepia(100%) hue-rotate(320deg) saturate(900%)!important;
  /* sepia(100%) hue-rotate(320deg) saturate(900%) */
}
.no-border-button-shadow{
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border: none !important;

}
.no-border-button-shadow button{
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border: none !important;
}
@media(max-width: 840px) {
  .display-no-title{
    display: none !important;
  }
}
@media(max-width: 600px){
  .button-text-row p{
    font-size: 1.5rem !important;
    font-weight: 700 !Important;
  }
  .button-text-row{
    font-size: 1.9rem !important;
  }
}
@keyframes generic-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes generic-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes menu-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    translate: -120vh;
    opacity: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in-lp {
  0% {
    opacity: 0;
    z-index: 1;
  }
  100% {
    opacity: 1;
    z-index: 1;
  }
}
@keyframes fade-out-lp {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
@-webkit-keyframes generic-fade-out {
   0% {
     opacity: 1;
   }
   100% {
     opacity: 0;
   }
 }
@-webkit-keyframes generic-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes menu-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    translate: -120vh;
    opacity: 0;
  }
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-lp {
  0% {
    opacity: 0;
    z-index: 1;
  }
  100% {
    opacity: 1;
    z-index: 1;
  }
}
@-webkit-keyframes fade-out-lp {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    display: none;
    opacity: 0;
    z-index: -1;
  }
}
body::-webkit-scrollbar {
  display: none;
}
.nav-menu::-webkit-scrollbar {
  display: none;
}
body {
  margin: 0;
  padding: 0;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  background: #000000;
}
section {
  margin: 0 auto;
  padding: 0;
  min-height: 100vh;
  min-width: 100vw;
  overflow: hidden;
}
.min-section{
    min-height: 1080px !important;
}
.min-section-trusted-by{
  min-height: 250px !important;
}
.arrow-down-projects{
   left: 0;
   right: 0;
   alignment: center;
   text-align: center;
   color: var(--text-primary);
   font-size: 7rem;
   padding: 13px;
   margin: 8px auto 8px auto;
 }
h1{
  color: whitesmoke;
}
p{
  color: whitesmoke;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
Link, a{
  color: var(--text-primary);
  text-decoration: none !important;
}
form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-badges{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto 0 2rem;
  padding: 0;
  bottom: -16px;
  right: 0;
  overflow: hidden;
}
.hero-badges a{
  padding: 2px;
  margin-bottom: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
  font-size: 5rem !important;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.hero-badges a:hover {
  scale: 0.9;
}
@media(min-width: 501px){
  .hero-badges{
    display: none;
  }
}
@media(min-height: 1000px){
  .hero-badges{
    display: none;
  }
}
@media(max-width: 500px) and (max-height: 820px){
  .hero-badges{
    margin: auto 0 2rem;

  }
}
@media(max-width: 500px){
  .hero-badges a{
    margin-bottom: 0.5rem !important;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    font-size: 2rem !important;
  }
}
@media(max-width: 380px){
  .hero-badges{
    margin: auto 0 2rem;
  }
  .hero-badges a{
    font-size: 1.875rem !important;
  }
}
/* STUDIO PANCAKE COMPONENTS */
.aiStyle {
  display: flex;
  position: relative;
  width: 140px;
  height: 140px;
  left: -1rem;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.iconStyle{
  display: flex;
  position: relative;
  width: 100px;
  height: 100px;
  left: 10px;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.iconStyle2{
  display: flex;
  position: relative;
  width: 80px;
  height: 80px;
  left: -10px;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.iconStyle3{
  display: flex;
  position: relative;
  width: 120px;
  height: 120px;
  left: -10px;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.iconStyle4{
  display: flex;
  position: relative;
  width: 100px;
  height: 100px;
  left: -10px;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.text-white-boldest{
  font-weight: 900 !important;
  color: white !important;
            }
.nav-container{
  -webkit-overflow-scrolling: touch;
  visibility: visible;
  position: fixed !important;
  height: max-content;
  z-index: 1000;
  margin: 0;
  background-color: transparent!important;
  -moz-border-radius-bottomright: 30px;
  border: 5px !important;
}
.navbar {
  width: 100vw;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0);
  color: white;
  font-family: Montserrat, serif;
  font-weight: 500;
}
.navbar-title{
  margin-right: auto;
  image-rendering: -webkit-crisp-edges;

}
.navbar-title img{
  image-rendering: -webkit-crisp-edges;
  object-fit: cover;
  -o-object-fit: cover;
  width: 12rem; /* or max-width: 100%; */
  height: auto;
  padding: 6px 2px 2px;
}
.navbar-item-logo{
  padding: 16px 16px;
  font-weight: 600;
  vertical-align: middle;
  transition: 0.5s;
  image-rendering: -webkit-crisp-edges;
}
.navbar-item {
  font-size: 1.35rem;
  padding: 16px 16px;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  border: 4px transparent;
  border-bottom: 4px solid rgb(255, 255, 255, 0);
  transition: 0.5s;
}
.navbar-item-last {
  font-size: 1.35rem;
  padding: 16px 16px;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  border: 4px transparent;
  border-bottom: 4px solid rgb(255, 255, 255, 0.4);
  transition: 0.5s;
}
.navbar-item:hover {
  border-bottom: 4px solid rgb(255, 255, 255, 0.9);
}
.navbar-item-last:hover {
  border-bottom: 4px solid rgb(255, 255, 255, 0.9);
}
.navbar-item-button {
  margin-right: 10px;
  border: solid 4px rgba(255, 255, 255, 0);
  padding: 16px 16px;
  font-weight: 600;
  vertical-align: middle;
}
.menu-control-container{
  position: relative;
  z-index: 10;
  padding: 8px;
  background-color: transparent;
  text-decoration: none !important;
}
.nav-menu{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  overflow: scroll;
  background-color: var(--bgPrimary-glass);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -webkit-animation: fade-in linear 0.5s forwards;
  animation: fade-in linear 0.5s forwards;
}
.menu-text-button{
  display: flex;
  text-align: center !important;
  margin: 0 auto;
  padding: 0;
  height: 8px;
}
.menu-text-button h1 {
  font-size: 1.25rem !important;
  font-weight: 700 !important;
}
.menu-logo-small{
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto 0;
  padding-top: 1rem;
  height: auto;
  width: 100%;
  background-color: transparent;
  overflow: hidden;
}
.menu-logo-small img{
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto 0;
  padding-top: 3rem;
  height: auto;
  width: 210px;
  background-color: transparent;
  overflow: hidden;
}
.menu-logo-small h3{
  padding-top: 1rem;
  font-weight: 400;
  font-size: 0.89rem;
  opacity: 0.89;
  margin-bottom: 0.5rem;
}
.row-form-small{
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto 0;
  padding-top: 13px;
  height: auto;
  width: 100%;
  background-color: transparent;
  overflow: hidden;
}
.form-button-small i{
  font-size: 1.5rem !important;
}
.form-button-small p{
  font-size: 1.8rem !important;
}

.form-button-small button{
  display: flex;
  height: 89px;
  width: 89px;
  justify-content: center;
  align-items: center;
  transition: all 0.33s ease-in !Important;
  -webkit-transition: all 0.33s ease-in !important;
  vertical-align: middle;
  flex-direction: column;
  margin: 1rem 0.66rem 1rem 0.66rem !important;
  padding: 1rem;
  border-radius: 10px;
  border: 4px solid rgb(245, 245, 245);
  color: rgb(255, 255, 255);
  background-color: rgb(10, 10, 10, 0);
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
}
.whatsapp-button-small{
  background-color: var(--PrimaryGreen) !important;
  border: 4px solid var(--PrimaryGreen) !important;
  transition: all 0.33s ease-in !Important;
  -webkit-transition: all 0.33s ease-in !important;
}
.contacts-button-small{
  background-color: #FAF9F6 !important;
  border: 4px solid #FAF9F6 !important;
  transition: all 0.33s ease-in !Important;
  -webkit-transition: all 0.33s ease-in !important;
}
.contacts-button-small p{
    color: black !important;
}
.call-button-small{
  background-color: var(--PrimaryBlue) !important;
  border: 4px solid var(--PrimaryBlue) !important;
  transition: all 0.33s ease-in !Important;
  -webkit-transition: all 0.33s ease-in !important;
}
.footer-logo-small{
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto 0;
  padding-top: 1rem;
  height: auto;
  width: 100%;
  background-color: transparent;
  overflow: hidden;
}
.footer-logo-small img{
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto 0;
    padding-top: 3rem;
    height: auto;
    width: 250px;
    background-color: transparent;
    overflow: hidden;
}
.footer-logo-small h3{
  padding-top: 1rem;
  font-weight: 400;
  font-size: 0.975rem;
  opacity: 0.89;
  margin-bottom: 0.5rem;
}

.menu-close-anim{
    animation: menu-fade-out linear 0.5s forwards;
    -webkit-animation: menu-fade-out linear 0.5s forwards;
}
.hover-link{
}
.video-state-button{
  position: relative;
  display: flex;
  left: 0;
  right: 0;
  bottom: 0;
  alignment: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  overflow: hidden;
  background-color: rgb(0, 0, 0, 0);
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}
.video-state-button button{
  position: absolute;
  font-size: 3rem;
  color: rgba(0, 0, 0, 0);
  left: 0;
  bottom: 0;
  width: 210px;
  height: 210px;
  vertical-align: middle;
  opacity: 1;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}
.spacer-menu-bottom{
  padding-bottom: 55px;
  margin-bottom: 6rem;
  overflow: hidden;
}
.spacer-bottom{
  padding-top: 55px;
  padding-bottom: 55px;
  overflow: hidden;
}
.spacer-25{
  padding-top: 4rem;
  padding-bottom: 4rem;
  overflow: hidden;
}
.spacer-3{
  padding-top: 3rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  overflow: hidden;
}
.spacer-4{
  padding-top: 4rem;
  padding-bottom: 4rem;
  overflow: hidden;
}
.spacer-5{
  padding-top: 5rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
  padding-bottom: 5rem;
  overflow: hidden;
}
@media (max-width: 550px) {
  .spacer-5{
    padding-top: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    overflow: hidden;
  }
  .spacer-25{
    display: none;
  }
}
/* Contact Button */
.contact-button-container{
  position: fixed;
  display: flex;
  visibility: visible;
  bottom: 0;
  right: 0;
  z-index: 9;
  padding: 8px 19px 8px 0;
  color: transparent;
  text-decoration: none !important;
  overflow: hidden;
  transition: all 0.5s ease;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
}
.contact-button{
  position: relative;
  right: -10px;
  height: 250px;
  width: 55px;
  z-index: 10;
  margin: 1.5rem auto;
  color: rgb(255, 255, 255, 1);
  background-color: transparent;
  font-weight: 600;
  text-decoration: none !important;
  border: rgba(255, 255, 255, 0) 2px solid;
  border-radius: 8px;
  border-right: none;
  overflow: hidden;
  transition: all 0.5s ease;
  writing-mode: vertical-lr;
}
.contact-button i{
  text-transform: uppercase;
  font-size: 2.5rem;
  padding-bottom: 2px;
  vertical-align: center;
  writing-mode: vertical-lr;
  text-align: center;
  font-weight: 600;
  line-height: 0;
  color: rgb(255, 255, 255, 1);
}
.contact-button-container:hover{
  transform: scale(0.95) translateX(2px);
}
@media(min-width: 920px){
  .contact-button-container{
    bottom: 34px;
  }
}
@media(min-width: 2000px){
  .navbar-item{
    font-size: 1.75rem;
    padding: 1.5rem 1.5rem;
  }
  .navbar-item-last{
    font-size: 1.75rem;
    padding: 1.5rem 1.5rem;
  }
  .navbar-title img{
    image-rendering: high-quality;
    object-fit: cover;
    -o-object-fit: cover;
    width: 9rem; /* or max-width: 100%; */
    height: auto;
    padding: 6px 2px 2px;
  }

}
@media(max-width: 1570px){
  .navbar-item{
    font-size: 0.77rem;
    padding: 16px 16px;
  }
  .navbar-item-last{
    font-size: 0.77rem;
    padding: 16px 16px;
  }
  .navbar-title img{
    image-rendering: high-quality;
    object-fit: cover;
    -o-object-fit: cover;
    width: 9rem; /* or max-width: 100%; */
    height: auto;
    padding: 6px 2px 2px;
  }

}
@media(max-width: 1000px){
  .navbar-item{
    font-size: 0.65rem;
    padding: 16px 16px;
  }
  .navbar-item-last{
    font-size: 0.77rem;
    padding: 16px 16px;
  }
  .navbar-title img{
    image-rendering: high-quality;
    object-fit: cover;
    -o-object-fit: cover;
    width: 9rem; /* or max-width: 100%; */
    height: auto;
    padding: 6px 2px 2px;
  }

}
@media (max-width: 921px) {
  .navbar-item{
    display: none;
  }
  .navbar-item-last{
    display: none;
  }
  .navbar-item-button{
    display: none;
  }
  .navbar-title{
    display: flex;
  }
  .menu-text-button {
    display: flex;
    padding: 16px 16px;
    margin: 16px;
    border: solid 4px rgba(255, 255, 255, 0);
    flex-direction: column;
    text-align: left;
    justify-content: space-evenly;
    text-decoration: none !important;
    font-weight: 600 !important;
    font-size: 32px !important;
  }
  a{
    color: whitesmoke !important;
    text-decoration: none !important;
    border-color: transparent !important;
  }
}

@media(max-width: 920px) {
  .navbar-title img {
    width: 7rem; /* or max-width: 100%; */
    image-rendering: high-quality;
  }
}
@media(max-width: 770px){
  .contact-button i{
    font-size: 2.1rem !important;
  }
  .navbar-title img {
    width: 100%;
  }
}
@media(max-width: 420px){
  .arrow-down-projects{
    font-size: 4.75rem !important;
  }
  .contact-button i{
    font-size: 1.9rem !important;
  }
  .contact-button-container{
    background-color: transparent;
    backdrop-filter: none;
  }
  .navbar-title img {
    width: 100%;
  }
  .aiStyle {
    width: 100px;
    height: 100px;
    left: -1rem;
  }
  .iconStyle{
    width: 65px;
    height: 65px;
    left: 5px;
  }
  .iconStyle2{
    width: 80px;
    height: 80px;
    left: -10px;
  }
  .iconStyle3{
    width: 80px;
    height: 80px;
    left: -10px;
  }
  .iconStyle4{
    width: 100px;
    height: 100px;
    left: -16px;
  }
}
@media(max-width: 380px) {

}
@media(max-height: 500px){
  .contact-button-container{
    display: none;
  }
}
/* NAVBAR */
@media (min-width: 922px) {
  .menu-control-container{
    display: none;
  }
}
/* General */
.section-reviews{
  display: flex;
  position: relative;
  padding: 0;
  margin: 0 auto;
  min-width: 100vw;
  min-height: 250px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  vertical-align: center;
  text-align: center;
  transition: all 0.5s ease-in;
  z-index: 3;
  overflow: hidden;
  background-color: transparent;
}
.image-reviews-container{
  padding-top: 89px;
  padding-bottom: 89px;
  width: 100%;
  left: 0;
  right: 0;
  align-content: center;
    justify-content: center;
    align-items: center;
    text-align: center;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}
.google-review-container img{
  flex: 50%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 34px 89px;
  display: block;
  margin: 0 auto;
  max-width:450px;
  width: auto;
  height: auto;
 }
@media(max-width: 1700px) and (min-height: 1301px){
  .google-review-container img{
    max-width: 340px;
  }
}
@media(max-width: 8000px) and (max-height: 1300px){
  .google-review-container img{
    max-width: 320px;
  }
}
@media(max-width: 2000px) and (max-height: 1300px){
  .google-review-container img{
    max-width: 300px;
  }
}
@media(max-width: 1500px) and (max-height: 1300px){
  .google-review-container img{
    max-width: 300px;
  }
}
@media(max-width: 2560px){
  .google-review-container img{
    max-width: 300px;
  }
}
@media(max-width: 1300px){
    .google-review-container img{
        max-width: 250px;
    }
}
@media(max-width: 420px){
  .google-review-container img{
    max-width: 200px;
  }
}

.section-quote{
  display: flex;
  position: relative;
  padding: 0;
  margin: 0 auto;
  min-width: 100vw;
  min-height: 400px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  vertical-align: center;
  text-align: center;
  transition: all 0.5s ease-in;
  z-index: 3;
  overflow: hidden;
  background-color: transparent;
}
.slider {
  position: relative !important;
  height: 42vh !important;
  min-height: 650px !important;
  pointer-events: none;
}
.slider a.previousButton {
  opacity: 0 !important;
}
.slider a.nextButton {
  opacity: 0 !important;
}
polygon {
  fill: white;
}
.animateIn{
}
.animateOut{
}

.badge-size {
  --badge-size: 150px;
}
.badge {
  /* A green circle */
  grid-row: auto;
  height: 10vw;
  min-height: 100px;
  max-height: 240px;
  width: 10vw;
  min-width: 100px;
  max-width: 240px;
  border-radius: 50%;
  background-color: transparent;
  border: calc(var(--badge-size) / 20) solid #00B67B;
  /* White initials */
  color: whitesmoke;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
  font-size: calc(var(--badge-size) / 1.75);
  font-weight: bold;
  /* And centered */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto 1rem;
}
.badge-avatar {
  /* Hide initials */
  color: transparent;
  /* Show image */
  background-position: center;
  background-size: cover;
}
.quote-review-container{
  flex-direction: column;
  width: 100%;
  height: auto;
  background: transparent;
  padding-top: 6rem;
  color: white;
  margin: 0 auto;
}
.quote-review{
  display: grid;
  border-radius: 12px;
  width: 95vw !important;
  height: auto;
  background: transparent;
  margin: 0 auto;
  align-content: center;
  justify-content: center;
  vertical-align: middle;
  text-align: center;
}
.quote-review p{
  grid-row: auto;
  position: relative;
  font-size: 1.5rem;
  font-weight: 500;
  padding-top: 1rem;
  margin: 1rem auto 2rem auto;
  left: 0;
  right: 0;
  max-width: 80vw;
  width: auto;
  height: auto;
  text-align: center;
}
.quote-review-button{
  grid-row: auto;
  border: 8px solid white;
  border-radius: 50px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
  text-align: center;
  width: 420px !important;
  left: 0;
  right: 0;
  margin: 0 auto;
  opacity: 0.89 !important;
}
.quote-review-button h2{
  font-size: 2rem;
  font-weight: 700;
  color: white !important;
  opacity: 1 !important;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  justify-content: center;
  text-align: center;
  align-items: center;
  align-content: center;
  margin: 0 auto;
  line-height: normal;
  display: inline-block;
  vertical-align: middle;
}
@media (max-width: 5020px){
  .quote-review p{
    width: 100%;
    max-width: 890px !important;
  }
}
@media (max-width: 770px){
  .slider {
    position: relative !important;
    height: 42vh !important;
    min-height: 450px !important;
  }
  .quote-review p{
    max-width: 85vw !important;
    font-size: 0.77rem;
  }
  .quote-review-button{
    position: relative;
    border-width: 4px !important;
    max-width: 80vw !important;
    height: auto;
  }
  .quote-review-button h2{
    font-size: 1rem !important;
    width: 100%;
  }
}
@media(min-width: 1700px) and (min-height: 1301px){
  .slider {
    position: relative !important;
    height: 42vh !important;
    min-height: 750px !important;
  }
  .quote-review-container{
    padding-top: 10rem;
  }
  .quote-review p{
    width: 42vw !important;
    min-width: 1200px !important;
    font-size: 2rem !important;
    padding: 0.5rem;
  }
}

.quote-container{
  flex-direction: column;
  padding-top: 12rem;
  padding-bottom: 6rem;
  width: 100%;
  min-height: 100vh;
  left: 0;
  right: 0;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}
.quote-container video{
  position: absolute;
  display: block;
  object-fit: cover;
  -o-object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 1080px;
  z-index: -1;
  filter: brightness(90%)!important;
  -webkit-filter: brightness(90%)!important;
}
.quote-container h1{
  text-transform: capitalize;
  font-size: 3rem;
  font-weight: 800;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto 3.5rem auto;
  width: auto;
  max-width: 95vw;
  height: auto;
  position: relative;
}
.quote-container h2{
  font-size: 2.5rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.77);
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0;
  margin: 1rem auto 1.5rem auto;
  width: auto;
  max-width: 95vw;
  height: auto;
}
.quote-button-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 1rem auto 3rem auto;
  width: auto;
  height: auto;
  gap: 2rem;
  overflow: hidden;
}
.quote-column{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  width: auto;
  height: auto;
  gap: 2rem;
}
.quote-button i{
  font-size: 2rem !important;
}
.quote-button button{
  display: flex;
  height: 89px;
  width: 560px;
  max-width: 20vw;
  justify-content: center;
  align-items: center;
  transition: all 0.33s ease-in;
  -webkit-transition: all 0.33s ease-in;
  vertical-align: middle;
  flex-direction: row;
  margin: 0 auto;
  padding: 10px;
  border-radius: 10px;
  border: 4px solid rgb(245, 245, 245);
  background-color: transparent;
  color: white;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
}
.quote-button button:hover{
  transform: scale(0.90);
  color: white !important;
  background-color: transparent !important;
}
@media(max-width: 2560px){
  .quote-container h1{
    font-size: 2.5rem;
  }
  .quote-container h2{
    font-size: 1.5rem;
  }
  .quote-button button{
    font-size: 1.5rem;
  }
}
@media(max-width: 1350px){
  .quote-container h1{
    font-size: 1.5rem;
  }
  .quote-container h2{
    font-size: 1rem;
  }
  .quote-button-container{
    flex-direction: column;
    gap: 1rem;
  }
  .quote-column{
    gap: 1rem;
  }
  .quote-button button{
    font-size: 1.25rem;
    max-width: 45vw !important;
    height: 77px !important;
    line-height: 0 !important;
  }
}
@media (max-width: 600px) {
  .quote-button button{
    font-size: 1rem;
    font-weight: var(--fontMobile-Weight-Semibold);
    max-width: 42vw !important;
    height: 66px !important;
    line-height: 0 !important;
  }
  .quote-button p{
    font-size: var(--fontMobileP);
    font-weight: var(--fontMobile-Weight-Semibold);
  }
}
@media (max-width: 420px) {
  .quote-container h1{
    font-size: 1.25rem;
  }
  .quote-container h2{
    font-size: 1rem;
    max-width: 90vw;
  }
}

.carousel-container{
  width: 100vw;
  height: 420px;
  min-height: 30vh;
  padding-top: 40px;
  padding-bottom: 70px;
  margin-bottom: 120px;
  overflow: hidden;
  position: relative;
  cursor: grab;
}
.review-title{
  padding: 2px;
  font-size: var(--fontDesktopH3);
  font-weight: 900;
  text-align: center;
  background-color: transparent;
  margin: 0 auto;
}
.review-container{
  position: absolute;
  height: 420px;
  width: 400px;
  max-width: 90vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  vertical-align: center;
    text-align: center;
  left: 0;
    right: 0;
  margin: 0 auto;

  display: flex;
  background-color: transparent;
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
}
.review-box{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  max-width: 90vw;
  height: 250px;
  border-radius: 20px;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0 auto;
  background-color: transparent;
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
  overflow: visible;
  filter: drop-shadow(0px 0px 20px rgb(0, 0, 0));

}
.review-box:before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: rgb(250, 250, 250, 1);
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
  clip-path: polygon(
          80px 0,
          100% 0,
          100% calc(100% - 80px),
          calc(100% - 80px) 100%,
          0 100%,
          0% 80px);
}
.review-box img{
  position: absolute;
  width: 100px;
  height: 100px;
  top: -60px;
  object-fit: cover;
  object-position: center;
  background-color: #a0a0a0;
  border-radius: 100%;
  border: rgb(250, 250, 250, 1) 5px solid;
  filter: grayscale(1);
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  overflow: visible;
  user-select: none;
}
.review-box p{
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  width: 340px;
  margin: 0 auto;
  vertical-align: center;
  font-size: 0.8rem;
  font-style: italic;
  font-weight: 600;
  text-align: center;
  overflow: visible;
  padding: 0;
  z-index: 3;
  color: black;
  user-select: none;
}
.review-box h1{
  position: absolute;
  top: 72%;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  padding: 10px;
  margin: 0 auto;
  color: rgba(0, 0, 0, 0.9);
  user-select: none;
}
.review-box h2{
  position: absolute;
  top: 68%;
  font-size: 0.7rem;
  font-weight: 600;
  text-align: center;
  padding: 5px;
  margin: 0 auto;
  color: rgba(0, 0, 0, 0.8);
  user-select: none;
}
.review-stars{
  position: absolute;
  top: 24%;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 1.1rem;
  color: mediumseagreen;
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
}
.review-container:hover > .review-box{
  transform: scale(1.05);
}
.review-container:hover > .review-box img{
  filter: grayscale(0.2);
  transform: scale(1.05);

}
@media (max-width: 1920px){
  .review-title{
    font-size: 2.5rem;
  }
}
@media (max-width: 1600px){
  .review-title{
    font-size: 2rem;
  }
}
@media (max-width: 600px) {
  .carousel-container{
    margin-bottom: 80px;
  }
  .review-title{
    position: relative;
    font-size: 1.5rem;
    bottom: -55px;
  }
  .review-container p{
    font-size: 0.7rem;
    font-weight: var(--fontMobile-Weight-Semibold);
  }
  .review-box h1{
    font-size: 1rem;
  }
  .review-box h2{
    font-size: 0.7rem;
  }
}
@media (max-width: 420px){
  .review-title{
    font-size: 1rem;
    width: 85vw;
  }
  .review-container:hover > .review-box{
    transform: none;
  }
  .review-container:hover:focus > .review-box img{
    filter: grayscale(0.2);
  }
}
.side-title-red{
  position: absolute;
  font-size: 10rem;
  font-weight: 900;
  min-height: 100vh;
  min-width: 50vw;
  top: 0;
  left: 0;
  margin: 10px auto;
  padding: 70px 0 0 10px;
  writing-mode: vertical-lr;
  text-align: center;
  z-index: 0;
  background-image: url(https://media.giphy.com/media/X3k79WjBE1dyWCS7xO/giphy-downsized-large.gif);
  background-size: auto;
  color: transparent;
  -webkit-background-clip: text;
}
.side-title-blue{
  position: absolute;
  font-size: 10rem;
  font-weight: 900;
  min-height: 100vh;
  min-width: 50vw;
  top: 0;
  left: 0;
  margin: 10px auto;
  padding: 70px 0 0 10px;
  writing-mode: vertical-lr;
  text-align: left;
  z-index: 0;
  background-image: url(https://media.giphy.com/media/rudLjMOuPMWjwTR1EL/giphy-downsized-large.gif);
  background-size: auto;
  color: transparent;
  -webkit-background-clip: text;
}

.opacity-0 {
  opacity: 0 !important;
}
.border-radius-video video{
  border: 2px solid transparent !important;
  border-radius: 10px !important;
}
@media (max-width: 770px) {
  .border-radius-video video{
    border: none !important;
    border-radius: 0 !important;
  }
}
/* VIDEO SECTION */
.videoSection{
  display: flex;
  position: relative;
  padding: 0;
  margin: 0 auto;
  min-width: 100vw;
  min-height: 100vh;
  height: 1080px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  vertical-align: center;
  z-index: 2;
  background-color: transparent;
}
.videoSection video{
  position: absolute;
  display: block;
  object-fit: cover;
  -o-object-fit: cover;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  height: 1080px;
  filter: grayscale(100%) brightness(70%)!important;
  -webkit-filter: grayscale(100%) brightness(70%)!important;
}

.videoSection h1{
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 2.5rem;
  font-weight: 800;
  margin: 0 auto 13px auto;
}
.videoSection p {
  position: relative;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  align-content: center;
  align-items: center;
  align-self: center;
  color: rgb(255, 255, 255, 0.89) !important;
  left: 0;
  right: 0;
  width: 90%;
  overflow: hidden;
  max-width: 800px;
  margin: 0 auto;
  white-space: pre-wrap;
  font-size: 1.5rem;
  text-align: center;
  alignment: center;
  word-break: break-word;
}
.middle-text-pos-container{
    position: absolute;
    bottom: 0;
    text-align: center;
}
@media(min-width: 1400px) {
  .videoSection h1{
    font-size: 2.875rem;
  }
  .videoSection p{
    font-size: 1.5rem;
    max-width: 700px;
  }

}
@media(max-width: 1200px){
  .videoSection h1{
    font-size: 2rem;
  }
  .videoSection p{
    font-size: 1rem;
    max-width: 600px;
  }
}
@media(max-width: 820px){
  .videoSection h1{
    font-size: 2rem;
  }
  .videoSection p{
    font-size: 1rem;
    max-width: 500px;
  }
}
@media(max-width: 680px){
  .videoSection h1{
    font-size: 2rem;
  }
  .videoSection p{
    font-size: 1rem;
    max-width: 380px;
  }
}
@media(max-width: 480px){
  .videoSection h1{
    font-size: var(--fontMobileH1);
    max-width: 90vw;
  }
  .videoSection p{
    font-size: var(--fontMobileP);
    max-width: 85vw;
  }
}
.bottom-element{
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  bottom: 0 !important;
}
.no-border button{
  border: none !important;
  max-width: 120px !important;
}
.icon-button-main button{
  display: flex;
  position: relative;
  font-size: 8rem;
  height: 64px;
  width: 90vw;
  max-width: 550px;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
  vertical-align: bottom;
  flex-direction: column;
  margin: 0 auto 0 auto;
  padding: 50px;
  border-radius: 10px;
  border: none;
  color: rgb(245, 245, 245, 1);
  background-color: rgb(10, 10, 10, 0);
  font-weight: 700;
  text-align: center;
}
.icon-button-main button:hover{
  transform: scale(0.85);
}
.top-element{
  top: 0 !important;
}
.bottom-element{
  bottom: 0 !important;
}
.text-heading-floating{
    position: relative;
    display: flex;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 80px;
    color: rgb(255, 255, 255, 1);
    font-size: 3.5rem;
    font-weight: 900;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-shadow: 0 0 10px rgb(0, 0, 0, 0.5);
    transition: all 0.5s ease-in;
}
.container-fluid-sticky {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 25%;
  min-height: 100%;
  overflow: hidden;
  color: var(--text-primary);
  background-color: transparent;
}
.container-fluid-sticky > .text-sticky {
  position: absolute;
  font-size: 5rem;
  font-weight: 900;
  min-height: 1080px;
  min-width: 100%;
  top: 0;
  left: 0;
  margin: 80px auto 0 auto;
  padding: 60px 0 0 0;
  writing-mode: vertical-lr;
  text-align: left;
  background-color: transparent;
  color: rgb(252, 252, 252, 0.95);
  white-space: nowrap;
  /* Mix Blend Mode does the all magic */
  user-select: none;
}
.container-fluid-sticky img{
  display: flex;
  position: absolute;
  padding: 15px;
  margin: 0 auto;
  top: 0;
  left: 0;
  width: 60px;
  overflow: hidden;
  image-rendering: -webkit-crisp-edges;
}
.bottom-20 button{
  margin-bottom: 89px !important;
}
@media(max-width: 770px){
  .container-fluid-sticky img{
    width: 55px;
  }
}
@media(max-width: 680px){
  .container-fluid-sticky img{
    width: 45px;
  }
}
@media(max-width: 620px){
  .container-fluid-sticky img{
    width: 37px;
  }
}
@media(max-width: 420px){
  .container-fluid-sticky img{
    width: 35px;
  }
}
.trade-mark-icon{
  font-size: 1.5rem;
  vertical-align: after-edge !important;
}
.section-trusted-by-container h3{
  font-size: 1rem !important;
  font-weight: 800 !important;
  color: var(--text-primary);
  text-align: center;
  margin: 0 auto;
  padding: 21px 0 0 0;
}
.spinner{
    position: absolute;
    display: flex;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.bottom-accent-icon{
  position: absolute;
  height: auto;
  width: auto;
  display: flex;
  opacity: 0.8;
  justify-content: right;
  right: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
  background: transparent;
  z-index: 0;
}
@media (max-width: 921px){
    .bottom-accent-icon{
      display: none;
      opacity: 0;
    }
}
/* Container should be full height and full width */
.container-fluid {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
  z-index: -1;
  color: var(--text-primary);
  background-color: transparent;
}
.bg-img {
  position: absolute;
  width: 50vw;
  height: 900px;
  overflow: hidden;
  object-fit: cover;
  -o-object-fit: cover;
  margin: 0;
  padding: 10px 0 0 0;
  z-index: 0;
}
/* background-color and color should be the same as the below otherwise it won't work */
.container-fluid > .text {
  position: absolute;
  font-size: 5rem;
  font-weight: 900;
  min-height: 100vh;
  min-width: 100%;
  top: 0;
  left: 0;
  margin: 80px auto 0 auto;
  padding: 20px 0 0 0;
  writing-mode: vertical-lr;
  text-align: left;
  background-color: transparent;
  color: rgb(252, 252, 252, 0.95);
  white-space: nowrap;
  /* Mix Blend Mode does the all magic */
  user-select: none;
}
@media (max-width: 840px) {
  .text-heading-floating {
    font-size: 2rem;
    padding: 70px;
  }
  .bg-img {
    width: 100vw;
    height: 300px;
  }
  /* background-color and color should be the same as the below otherwise it won't work */
  .container-fluid > .text {
    font-size: 3.8rem;
    writing-mode: horizontal-tb;
    text-align: center;
    position: relative;
    display: grid;
  }
  .container-fluid-sticky > .text-sticky {
    font-size: 3.8rem;
  }
}
@media (max-width: 640px){
  /* background-color and color should be the same as the below otherwise it won't work */
  .container-fluid > .text {
    font-size: 2.8rem;
    margin: 80px auto;
  }
  .container-fluid-sticky > .text-sticky {
    font-size: 2.8rem;
  }
  .trade-mark-icon{
    font-size: small;
  }
  .icon-button-main button{
    font-size: 5rem;
  }
}
@media (max-width: 420px){
  .text-heading-floating{
    font-size: 1.44rem;
    padding: 70px;
  }
  .slab5-project button{
    line-height: 0;
    font-size: var(--fontMobile-Button-Text);
  }
  /* background-color and color should be the same as the below otherwise it won't work */
  .container-fluid > .text {
    font-size: 1.9rem;
    margin: 74px auto;
  }
}
@media (max-width: 380px){
  .container-fluid > .text {
    font-size: 1.7rem;
  }
}
@media (max-width: 1000px) and (max-height: 600px){
  .container-fluid > .text {
    font-size: 3rem;
  }
}
/* Section 1 */
.section1{
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  background-color: transparent;
  z-index: 1;
}
.section1-content{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
  left: 0;
  right: 0;
  height: 500px;
  width: 90vw;
  text-align: center;
  vertical-align: center;
  color: white;
  font-family: Montserrat, serif;
  font-weight: 500;
  font-size: 1.5rem;
  opacity: 1;
  z-index: 1;
}
.section1Video video{
  position: fixed;
  display: flex;
  object-fit: cover;
  -o-object-fit: cover;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  z-index: -10;
  background: black;
  filter: brightness(80%);
  -webkit-filter: brightness(80%);
}
.darken-video-hero video{
  animation: 2s forwards darken 0.1s !important;
  -webkit-animation: 2s forwards darken 0.1s !important;
}
.hero-video-state-button{
  position: absolute;
  opacity: 0.77;
  background: transparent;
  color: white;
  border: none;
  font-size: 2rem;
  padding: 0;
  margin: 1rem 1rem 1.5rem 1rem;
  bottom: 0;
  left: 0;
  z-index: 3;
}
@media (min-width: 650px) {
  .hero-video-state-button{
    font-size: 3rem;
  }
}
@media (min-width: 1200px) {
  .hero-video-state-button{
    font-size: 3.5rem;
  }
}
@media (min-width: 2560px) {
  .hero-video-state-button{
    font-size: 4.5rem;
  }
}
@keyframes darken {
  from{
    filter: brightness(80%);
    -webkit-filter: brightness(80%);
  }
  to{
    filter: brightness(20%);
    -webkit-filter: brightness(20%);
  }
}
@-webkit-keyframes darken {
  from{
    filter: brightness(80%);
    -webkit-filter: brightness(80%);
  }
  to{
    filter: brightness(20%);
    -webkit-filter: brightness(20%);
  }
}

@-webkit-keyframes grey {
  from{
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }
  to{
    filter: grayscale(0%);
    -webkit-filter: grayscale(0%);
  }
}
@keyframes grey {
  from{
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }
  to{
    filter: grayscale(0%);
    -webkit-filter: grayscale(0%);
  }
}

.section1Video h1{
  position: fixed;
  width: 100vw;
  height: 200px;
  color: rgb(245, 245, 245, 0.7);
  font-family: Montserrat, sans-serif;
  font-size: 3rem;
  font-weight: 900;
  text-align: center;
  align-content: center;
  alignment: center;
  display: table;
  top: 74px;
  margin: auto;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
}
.section1Video h2{
  position: fixed;
  height: 200px;
  color: rgb(245, 245, 245, 0.4);
  font-weight: 900;
  text-align: center;
  align-content: center;
  alignment: center;
  display: table;
  top: 0;
  margin: auto;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  font-family: Montserrat, sans-serif;
  font-size: 3.3rem;
}
.section1-content button{
  position: fixed;
  display: table;
  margin: 0 auto;
  left: 0;
  top: 70vh;
  right: 0;
  width: 70vw;
  max-width: 460px;
  height: 60px;
  color: rgb(245, 245, 245, 1);
  background-color: var(--bgPrimary);
  border: 4px solid rgba(217, 217, 217, 1);
  border-radius: 10px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  text-align: -webkit-center;
  align-content: center;
  -webkit-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out;
  line-height: 0;
}
.section1-content button:hover{
  transform: scale(0.95);
  border: 4px solid rgb(255, 255, 255, 0.7);
  color: #0a0a0a;
  background-color: rgb(255, 255, 255);
}
.scroll-down{
  position: absolute;
  bottom: 1rem;
  text-align: center;
  left: 0;
  right: 0;
  color: rgba(255, 255, 255, 0.89);
  align-items: center;
  alignment: center;
  margin: auto;
  font-size: 4rem !important;
  z-index: 2;
}

.spacer-hero-title{
  margin: 0 auto 2rem;
}
.override-title{
  top: 0 !important;
  margin: 21px auto 21px auto !important;
  bottom: 0 !important;
  position: relative !important;
}
.hero-title-main{
  position: absolute;
  display: inline;
  top: 32%;
  bottom: 0;
  width: 100% !important;
  max-width: 100% !important;
  left: 0;
  right: 0;
  text-align: center;
  align-items: center;
  font-weight: 900;
  color: rgba(255, 255, 255, 0.55);
  background-color: transparent !important;
  margin: 0 auto;
  font-size: 6rem !important;
  z-index: 2;
  overflow: hidden;
}
.hero-title-main h3{
  position: relative;
  font-size: 1rem;
  font-style: oblique;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.89);
  margin: 0 auto 1rem auto;
  z-index: 2;
  overflow: hidden;
}
.scroll-down-icon-main-2{
  position: absolute;
  bottom: -1rem !important;
  text-align: center;
  height: 300px;
  width: 300px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 2;
  opacity: 0.9 !important;
}
.cta-main-color button{
  background: var(--PrimaryBlue) !important;
  color: white !important;
  border-color: var(--PrimaryBlue) !important;
}
.cta-main-color button:hover{
  background: #006ee6 !important;
  color: white !important;
  border-color: var(--PrimaryBlue) !important;
}

.override-secondary-cta{
  margin-top: 55px !important;
  border: none !important;
  color: white !important;
  background-color: transparent !important;
}
.override-secondary-cta button{
  border: none !important;
  margin-top: 55px !important;
  color: white !important;
  background-color: transparent !important;
}
@media(max-height: 900px) and (max-width: 1920px) {
  .scroll-down-icon-main-2{
    height: 100px !important;
    width: 200px;
  }
}
@media(max-height: 1200px){
  .scroll-down-icon-main-2{
    height: 200px;
    width: 200px;
  }
}
@media(max-height: 980px){
  .hero-title-main{
    font-size: 3rem !important;
    max-width: 1000px;
  }
  .scroll-down-icon-main-2{
    height: 150px;
    width: 200px;
  }
}
@media(max-height: 1300px) and (max-height: 8560px){
  .hero-title-main{
    font-size: 6rem !important;
    max-width: 1400px;
  }
  .scroll-down-icon-main{
    height: 250px;
    width: 250px;
  }
}
@media(max-height: 1300px) and (max-height: 2560px){
  .hero-title-main{
    font-size: 3.5rem !important;
    max-width: 900px;
  }
  .scroll-down-icon-main{
    height: 250px;
    width: 250px;
  }
}
@media(max-width: 2560px){
  .hero-title-main{
    font-size: 5rem !important;
    max-width: 1400px;
  }
  .scroll-down-icon-main{
    height: 210px;
    width: 210px;
  }
}
@media(max-width: 1700px){
  .hero-title-main{
    font-size: 3.5rem !important;
    max-width: 900px;

  }
  .scroll-down-icon-main{
    height: 210px;
    width: 210px;
  }
}
@media(max-width: 1400px){
  .hero-title-main{
    font-size: 4rem !important;
    max-width: 1000px;
  }
  .scroll-down-icon-main{
    height: 180px;
    width: 180px;
  }
}
@media(max-width: 1200px){
  .hero-title-main{
    font-size: 3.5rem !important;
    max-width: 900px;
  }
  .scroll-down-icon-main{
    height: 130px;
    width: 130px;
  }
}
@media(max-width: 1600px) and (max-height: 1200px){
  .hero-title-main{
    font-size: 3.5rem !important;
    max-width: 900px;
  }
  .scroll-down-icon-main{
    height: 90px;
    width: 90px;
  }
}
@media(max-width: 840px) and (max-height: 1200px){
  .hero-title-main{
    font-size: 3rem !important;
    max-width: 700px;
  }
  .scroll-down-icon-main{
    height: 90px;
    width: 90px;
  }
}
@media(max-width:790px){
  .hero-title-main{
    font-size: 2rem !important;
    max-width: 500px;
  }
  .scroll-down-icon-main{
    height: 90px;
    width: 90px;
  }
}
@media(max-width: 802px) and (max-height: 602px){
  .hero-title-main{
    font-size: 2.4rem !important;
    max-width: 700px;
  }
  .scroll-down-icon-main{
    height: 90px;
    width: 90px;
  }
}
@media(max-width: 1400px) and (max-height: 1030px){
  .hero-title-main{
    font-size: 3rem !important;
    max-width: 800px;
  }
  .scroll-down-icon-main{
    height: 120px;
    width: 120px;
  }
}
/* PROPOSITION OFF */
@media(max-width: 790px){
  .override-secondary-cta button{
    max-width: 60vw !important;
  }
  .cta-main-color button{
    max-width: 60vw !important;
    font-size: 1.325rem !important;
    font-weight: bold !important;
  }
  .hero-title-main{
    font-size: 2.5rem !important;
    max-width: 600px;
  }
  .hero-title-main h3{
    font-size: 0.75rem !important;
  }
  .scroll-down-icon-main{
    height: 90px;
    width: 90px;
  }
}
@media(max-width: 660px){
  .hero-title-main{
    font-size: 2rem !important;
    max-width: 600px;
  }
  .cta-main-color button{
    max-width: 60vw !important;
    font-size: 1.2rem !important;
    font-weight: bold !important;
  }
  .override-secondary-cta button{
   font-size: 1.2rem !important;
  }
}
@media(max-width: 560px){
  .hero-title-main{
    font-size: var(--fontMobileH1) !important;
    max-width: 500px;
  }
  .hero-title-main h3{
    font-size: 0.625rem !important;
  }
  .override-secondary-cta button{
    font-size: 1rem !important;
  }
}
@media(max-width: 379px){
  .hero-title-main{
    font-size: 1.475rem !important;
    max-width: 360px;
  }
  .cta-main-color button{
    font-size: 0.89rem !important;
    font-weight: bold !important;
  }
  .override-secondary-cta button{
    font-size: 1rem !important;
  }
}
/* iPhone 4 */
@media(max-width: 320px) and (max-height: 480px){
  .hero-badges{
    display: none;
  }
  .scroll-down-icon-main-2{
    display: none;
  }
  .hero-title-main{
    font-size: 1rem !important;
    max-width: 360px;
  }
  .cta-main-color{
    display: none;
  }
}
@media (max-width: 2560px){
  .scroll-down{
    font-size: 3.5rem !important;
  }
}
@media (max-width: 1500px){
  .scroll-down{
    font-size: 3rem !important;
  }
}
@media(max-width: 900px){

}
.scroll-down i h3{
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  align-content: center;
  alignment: center;
  padding: 10px;
  margin: 0 auto;
}
.fade-out-lp{
  animation: fade-out-lp linear 0.2s forwards;
  -webkit-animation: fade-out-lp linear 0.2s forwards;
}
.fade-in-lp{
  animation: fade-in-lp linear 0.8s forwards;
  -webkit-animation: fade-in-lp linear 0.8s forwards;
}
@media (max-width: 480px) {
  .navbar-title img{
    width: 80%; /* or max-width: 100%; */
  }
  .section1{
    height: 1000px;
  }
  .section1Video video{
    height: 1000px;
    width: 100vw;
    overflow: hidden;
  }
  .section1Video h1{
    font-size: 1.6rem;
  }
  .section1Video h2{
    font-size: 1.75rem;
  }
  .section1-content button{
    top: 60vh;
    width: 80vw;
    height: 50px;
    line-height: 0;
    max-width: 460px;
    font-size: 0.9rem;
  }
}
@media (max-height: 600px) and (min-width: 481px) {
  .section1Video h1{
    font-size: 1.5rem;
  }
  .section1Video h2{
    font-size: 1.5rem;
  }
  .section1-content button{
    top: 65vh;
    width: 60vw;
    max-width: 460px;
    height: 60px;
    font-size: 0.9rem;
  }
}
/* Section 2 */
.section2{
  display: flex;
  position: relative;
  padding: 0;
  margin: 0 auto;
  width: 100vw;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  vertical-align: center;
  color: var(--text-primary);
  background: rgb(0, 0, 0, 0);
  z-index: 2;
}
.section2-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: left;
  vertical-align: top;
  width: 90vw;
  max-width: 600px;
  background: transparent;
  z-index: 2;
}
.section2 h1{
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  align-content: center;
  alignment: center;
  z-index: 2;
}
.slab-container{
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 100px auto 100px;
  z-index: 2;
}
.slab-container > div{
  padding:10px;
  background: rgb(0, 0, 0, 0);
}
.slabs-visibility{
  visibility: hidden;
}
.is-visible{
  visibility: visible;
}
.slab1{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  background: rgb(0, 0, 0, 0);
}
.slab1 img {
  display: flex;
  vertical-align: middle;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: var(--slab-width);
  height: var(--slab-height);
  object-fit: cover;
  -o-object-fit: cover;
  image-rendering: high-quality;
  padding: 2px;
  border-radius: 21px;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  margin-top: var(--slab-margin-desktop);
  margin-bottom: var(--slab-margin-desktop);
}
.slab1 h2{
  position: absolute;
  width: auto;
  height: auto;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  transform: translateZ(20px);
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
}
.slab1:hover{
  transform: scale(var(--slab-scale-down));
  box-shadow: 32px 32px 21px hsl(0, 0%, 0%, 0);
  -webkit-box-shadow: 32px 32px 21px hsl(0, 0%, 0%, 0);
}
.slab1:hover img{
  box-shadow: 32px 32px 21px hsl(0, 0%, 0%, 0);
  -webkit-box-shadow: 32px 32px 21px hsl(0, 0%, 0%, 0);
}
@media (max-width: 600px) {
  .slab-container > div{
    margin:1%;
    padding:10px;
  }
  .slab1 img{
    height: 140px;
    width: 88vw;
    border-radius: 13px;
    margin-top: var(--slab-margin-mobile);
    margin-bottom: var(--slab-margin-mobile);
  }
  .slab1 h2{
    font-size: var(--fontMobileH2);
  }
}
/* Section 3 */
.section3{
  display: flex;
  position: relative;
  padding: 0;
  margin: 0 auto;
  min-width: 100vw;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  vertical-align: center;
  background: var(--bgPrimary-glass);
  transition: all 0.5s ease-in;
  z-index: 3;
}
.section3-container {
  opacity: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  text-align: left;
  justify-content: left;
  width: 90vw;
  max-width: 600px;
  color: var(--text-primary);
  background: rgb(255, 255, 255, 0);
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 100px auto 100px;
  z-index: 3;
}
.section3-container h1{
  font-size: 2rem;
  padding: 2px;
  font-weight: bold;
  text-align: center;
  alignment: center;
  margin: 1rem;
}
.section3-container p{
  color: var(--text-secondary);
  font-size: var(--fontDesktopP);
  padding: 2px;
  font-weight: 500;
  text-align: center;
  alignment: center;
  margin: 1rem;
}
.section3-button-container{
  left: 0;
  right: 0;
  padding-top: 0.7rem;
  align-items: center;
}
.section3 button{
  width: 85vw;
  max-width: 565px;
  height: 60px;
  color: rgb(245, 245, 245, 1);
  background-color: rgb(10, 10, 10, 0);
  border: 4px solid rgb(217, 217, 217, 1);
  border-radius: 10px;
  padding: 16px 16px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  text-align: -webkit-center;
  align-content: center;
  position: relative;
  display: table;
  margin: 0 auto;
  left: 0;
  bottom: 0;
  right: 0;
  -webkit-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out;
  line-height: 0;
}
.section3 button:hover{
  transform: scale(0.95);
  border: 4px solid rgb(255, 255, 255, 0.7);
  color: #0a0a0a;
  background-color: rgb(255, 255, 255);
}
@media (max-width: 480px) {
  .section3-container h1{
    font-size: var(--fontMobileH1);
    font-weight: var(--fontMobile-Weight-Bold);
  }
  .section3-container p{
    font-size: var(--fontMobileP);
    font-weight: var(--fontMobile-Weight-Semibold);
  }
  .section3 button{
    width: 80vw;
    height: 50px;
    line-height: 0;
    font-size: var(--fontMobile-Button-Text);
  }
  .slab5-project button{
    width: 90vw !important;
    height: 50px !important;
    line-height: 0 !important;
    font-size: var(--fontMobile-Button-Text) !important;
  }
  .slab5-project p{
    font-size: var(--fontMobileP);
    font-weight: var(--fontMobile-Weight-Semibold);
  }
}
@media (max-height: 600px) and (min-width: 481px) {
  .section3 button{
    font-size: 0.9rem;
  }
}
/* Section Trusted By */
.section-trusted-by{
  display: flex;
  position: relative;
  padding: 0;
  margin: 0 auto;
  min-width: 100vw;
  min-height: 250px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  vertical-align: center;
  text-align: center;
  background: transparent;
  transition: all 0.5s ease-in;
  z-index: 4;
}
.section-trusted-by-container {
  opacity: 1;
  display: flex;
  position: absolute;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  max-height: 250px;
  width: 90vw;
  padding-top: 30px;
  max-width: 600px;
  color: var(--text-primary);
  background: rgb(255, 255, 255, 0);
  z-index: 3;
}
.row-logos{
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  width: auto;
  max-height: 100%;
  max-width: 100vw;
  background-color: transparent;
  overflow: hidden;
}
.powered-by-logo{
  position: relative;
  display: flex;
  image-rendering: optimizeSpeed;
  width: 21%;
  height: 21%;
  padding: 10px;
  margin: 1px auto;
  user-select: none;
}
.trusted-by-margins{
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.scale-up-logo{
  scale: 1.2;
}
.scale-up-bh-logo{
  scale: 1.3;
}
.scale-down-logo{
  scale: 0.8;
}
.scale-down-qm-logo{
    scale: 0.85;
}
.scale-down-sp-logo{
  scale: 0.77;

}
@media (max-width: 600px){
  .trusted-by-margins{
    margin-right: 1px !important;
    margin-left: 1px !important;
  }
  .section-trusted-by-container h3{
    font-size: 0.7rem;
  }
}
.section-powered-by{
  display: flex;
  position: relative;
  padding: 0;
  margin: 0 auto;
  min-width: 100vw;
  min-height: 250px !important;
  height: 100% !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  vertical-align: center;
  text-align: center;
  background: transparent;
  transition: all 0.5s ease-in;
  z-index: 3;
}
.section-trusted-by-container h3{
   font-size: 1rem;
   padding: 4px;
   font-weight: bolder;
   text-align: center;
   alignment: center;
   margin: 0 auto;
 }
.section-bar{
  display: flex;
  position: relative;
  padding: 0;
  margin: 0 auto;
  min-width: 100vw;
  min-height: 33px !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  vertical-align: center;
  text-align: center;
  background: var(--bgPrimary-glass);
  transition: all 0.5s ease-in;
  z-index: 3;
}
.section-trusted-by-container h1{
  font-size: 2rem;
  padding: 2px;
  font-weight: bold;
  text-align: center;
  alignment: center;
  margin: 1rem;
}
.section-trusted-by-container p{
  color: rgba(255, 255, 255, 0.8);
  font-size: var(--fontDesktopP);
  padding: 2px;
  font-weight: 500;
  text-align: center;
  alignment: center;
  margin: 1rem;
}
.section-trusted-by-button-container{
  left: 0;
  right: 0;
  padding-top: 0.7rem;
  align-items: center;
}
.section-trusted-by button{
  width: 85vw;
  max-width: 565px;
  height: 60px;
  color: rgb(245, 245, 245, 1);
  background-color: var(--bgPrimary);
  border: 4px solid rgb(217, 217, 217, 1);
  border-radius: 10px;
  padding: 16px 16px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  text-align: -webkit-center;
  align-content: center;
  position: relative;
  display: table;
  margin: 0 auto;
  left: 0;
  bottom: 0;
  right: 0;
  -webkit-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out;
  line-height: 0;
}
.section-trusted-by button:hover{
  transform: scale(0.95);
  border: 4px solid rgb(217, 217, 217, 0.7);
}
@media (max-width: 480px) {
  .section-trusted-by{
    min-height: 170px !important;
  }
  .section-powered-by{
    min-height: 170px !important;
  }
  .section-trusted-by-container h1{
    font-size: var(--fontMobileH1);
    font-weight: var(--fontMobile-Weight-Bold);
  }
  .section-trusted-by-container p{
    font-size: var(--fontMobileP);
    font-weight: var(--fontMobile-Weight-Semibold);
  }
  .section-trusted-by button{
    width: 80vw;
    height: 50px;
    line-height: 0;
    font-size: var(--fontMobile-Button-Text);
  }
}
@media (max-height: 600px) and (min-width: 481px) {
  .section-trusted-by button{
    width: 100vw;
    height: 60px;
    font-size: 0.9rem;
  }
}
/* Section Projects */
.section-project{
  display: flex;
  position: relative;
  margin: 0 auto;
  padding: 0 0 55px 0;
  width: 100vw;
  height: 1280px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  vertical-align: center;
  background: rgba(128, 128, 128, 0);
  color: var(--text-primary);
  background: black;
  z-index: 2;
  overflow: hidden;
}
.section-project-container {
  display: flex;
  position: relative;
  flex-direction: column;
  text-align: left;
  justify-content: left;
  vertical-align: top;
  width: 90vw;
  max-width: 600px;

  background: transparent;
  z-index: 2;
  margin: 100px auto 100px;
  overflow: hidden;
}
.section-project h1{
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  align-content: center;
  alignment: center;
  z-index: 2;
}
.slab1-project{
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
  overflow: hidden;
  filter: drop-shadow(0px 0px 30px rgb(0, 0, 0, 1));
}
.slab1-project img{
  display: flex;
  vertical-align: middle;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: var(--slab-project-width);
  height: var(--slab-project-height);
  object-fit: cover;
  -o-object-fit: cover;
  image-rendering: smooth;
  padding: 0;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}
.slab1-project:hover{
  transform: scale(var(--slab-scale-down));
}
.slab1-project h2{
  position: absolute;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  transform: translateZ(20px);
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
  overflow: hidden;
}
.border-radius-slab-top {
  margin-top: 20px !important;
}
.border-radius-slab-top img{
    border-top-right-radius: 4px !important;
    border-top-left-radius: 4px !important;
}
.border-radius-slab-bottom {
  margin-bottom: 20px !important;
}
.border-radius-slab-bottom img{
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
.no-bg-hover button:hover{
  color: var(--text-primary) !important;
  background: none !important;
}
.limited-border{
  border: none !important;
  margin: 13px auto 34px !important;
  padding: 2px !important;
}
.limited-border button{
  color: white !important;
  background-color: transparent !important;
  border: none !important;
  margin: 13px auto 34px !important;
  padding: 2px !important;
}
.limited-border-2{
  border: none !important;
}
.limited-border-2 button{
  border: none !important;
}
.normal-text-button button{
  display: flex;
  height: 72px;
  width: 90vw;
  max-width: 550px;
  justify-content: center;
  align-items: center;
  transition: all 0.33s ease-in !important;
  -webkit-transition: all 0.33s ease-in !important;
  vertical-align: middle;
  flex-direction: column;
  margin: 0 auto;
  padding: 0;
  border-radius: 10px;
  border: 4px solid rgb(245, 245, 245);
  color: rgb(245, 245, 245, 1);
  background-color: rgb(10, 10, 10, 0);
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
}
.normal-text-button button:hover{
  transform: scale(0.95);
  border: 4px solid rgb(255, 255, 255, 0.7);
  color: #0a0a0a;
  background-color: rgb(255, 255, 255);
}
.scroll-down-icon-main{
  position: absolute;
  font-size: 4rem;
  alignment: center;
  align-items: center;
  text-align: center;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 33px;
  background: transparent;
}
.slab5-project i{
  font-size: 2rem !important;
}
.slab5-project-white button{
  border: 4px solid white !important;
  color: black !important;
  background-color: white !important;
}
.slab5-project button{
  display: flex;
  height: 89px;
  width: 90vw;
  max-width: 560px;
  justify-content: center;
  align-items: center;
  transition: all 0.33s ease-in;
  -webkit-transition: all 0.33s ease-in;
  vertical-align: middle;
  flex-direction: column;
  margin: 13px auto 21px;
  padding: 10px;
  border-radius: 10px;
  border: 4px solid var(--PrimaryBlue);
  color: white;
  background-color: var(--PrimaryBlue);
  box-shadow:
          0 1px 1px hsl(0deg 0% 0% / 0.075),
          0 2px 2px hsl(0deg 0% 0% / 0.075),
          0 4px 4px hsl(0deg 0% 0% / 0.075),
          0 8px 8px hsl(0deg 0% 0% / 0.075),
          0 16px 16px hsl(0deg 0% 0% / 0.075);
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
}
.slab5-project button:hover{
  transform: scale(0.90);
  color: white !important;
  background-color: transparent !important;
}
@media (max-width: 600px) {
  .slab1-project img{
    height: 210px;
    width: 90vw;
    overflow: hidden;
  }
  .slab1-project h2{
    font-size: var(--fontMobileH2);
  }
  .slab5-project button{
    font-size: 1.2rem;
    font-weight: 700;
    max-width: 85vw !important;
    height: 72px !important;
    line-height: 0 !important;
  }
  .slab5-project p{
    font-size: var(--fontMobileP);
    font-weight: var(--fontMobile-Weight-Semibold);
  }
  .normal-text-button button{
    font-size: var(--fontMobileP);
    font-weight: var(--fontMobile-Weight-Semibold);
    max-width: 85vw !important;
    height: 50px !important;
    line-height: 0 !important;
  }
  .normal-text-button p{
    font-size: var(--fontMobileP);
    font-weight: var(--fontMobile-Weight-Semibold);
  }
}
@media (max-width: 420px) {
  .slab1-project img{
    height: 210px;
    width: 100vw;
    clip-path: polygon(
            0 0,
            100% 0,
            100% calc(100% - 80px),
            100% 100%,
            0 100%,
            0 0);
  }
  .border-radius-slab-top img{
    border-radius: 0 !important;
  }
  .border-radius-slab-bottom img{
    border-radius: 0 !important;
  }
  .section-project{
    padding: 55px 0 34px 0;
  }
}
/* DESKTOP PROJECTS */
.section-min-height{
  min-height: 1080px !important;
}
.main-project-bottom-buttons{
    position: absolute;
    width: 560px;
    max-width: 100vw;
    margin: 0 auto;
    text-align: center;
    alignment: center;
    align-items: center;
    align-content: center;
    bottom: 2rem !important;
    vertical-align: center;
    left: 0 !important;
    right: 0 !important;
  }
.main-project-bottom-buttons h2{
  font-size: 2rem;
  font-weight: 700;
  color: #f5f5f5;
  margin: 0;
  padding: 0;
}
.main-project-bottom-buttons h3{
  text-align: left;
  font-size: 0.9rem;
  font-weight: 400;
  color: #f5f5f5;
  margin: 0 auto;
  padding-top: 13px;
  padding-bottom: 8px;
}
.main-project-bottom-buttons p{
  font-size: 1rem;
  font-weight: 400;
  color: #f5f5f5;
  margin: 0;
  padding: 13px;
}
.main-project{
  position: absolute;
  top: 0;
  width: 100vw;
  height: 1400px;
  background: black;
  mask-image: linear-gradient(to right, #0a0a0a 66%, transparent 100%);
}
.main-project img{
  object-fit: cover;
  -o-object-fit: cover;
  width: 100vw;
  height: 620px;
  -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgb(0,0,0,1)), to(rgb(10,10,10,0)));
  mask-image: -webkit-gradient(linear, left top, left bottom, from(rgb(0,0,0,1)), to(rgb(10,10,10,0)));
}
.main-project-info-container{
  top: 12%;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: left;
  width: 100vw;
  height: 300px;
  background: transparent;
}
.main-project-title{
  top: 10%;
  position: relative;
  width: 100vw;
  background: transparent;
  padding: 34px;
}
.main-project-title h1{
  font-size: 3rem;
  text-align: left;
  padding: 0;
  margin: 0 auto;
}
.main-project-title p{
  font-size: var(--fontDesktopPSmall);
  width: 550px;
  height: 80px;
  word-break: break-word;
  margin-bottom: 1rem;
}

/* SUB PROJECTS  IMAGES*/
.sub-carousel-pos{
  position: relative;
  top: 90px !important;
  height: 500px !important;
  cursor: grab;
  touch-action: pan-y;
}
.sub-project-container{
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  top: 450px;
  left: 0;
  right: 0;
  width: 100vw;
  height: 240px;
  background-color: transparent !important;
  overflow: auto;
  overflow-y: hidden;
  overflow-scrolling: touch;
}
.sub-project-slab{
  position: absolute;
  display: flex;
  flex-direction: column;
  height: auto;
  text-align: center;
  background: transparent;
  vertical-align: middle;
  transition: all 0.33s ease-in !Important;
  -webkit-transition: all 0.33s ease-in !important;
}
.sub-project-slab:hover{
  transform: scale(0.95);
}
.sub-project-slab img{
  display: flex;
  position: relative;
  width: 360px;
  height: 180px;
  object-fit: cover;
  -o-object-fit: cover;
  margin: 0 auto;
  border: transparent 4px solid;
  border-radius: 13px;
  background-color: transparent !important;
  -webkit-mask-image: none;
  user-select: none;
  filter: brightness(89%);
  -webkit-filter: brightness(89%);
  transition: all 0.33s ease-in !Important;
  -webkit-transition: all 0.33s ease-in !important;
}
.sub-project-slab h2{
  display: flex;
  position: absolute;
  width: auto;
  height: auto;
  flex-direction: column;
  top: 45%;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0 auto;
  vertical-align: middle;
  text-align: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.5rem;
  user-select: none;
  overflow: hidden;
  background: transparent;
  transition: all 0.33s ease-in !Important;
  -webkit-transition: all 0.33s ease-in !important;
}

.button-container-row {
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 100vw;
  background: transparent;
  margin-bottom: 0.5rem;
}
.override-project-button{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: left;
  transition: all 0.33s ease-in !Important;
  -webkit-transition: all 0.33s ease-in !important;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
.override-project-button button{
  position: absolute;
  display: flex;
  height: 77px;
  width: 90vw;
  max-width: 420px;
  flex-direction: row;
  text-align: center;
  text-align-all: center;
  justify-content: center;
  align-items: center;
  transition: all 0.33s ease-in;
  -webkit-transition: all 0.33s ease-in;
  vertical-align: middle;
  margin: 0.5rem auto 0.5rem;
  border-radius: 10px;
  border: 4px solid var(--PrimaryBlue);
  color: white;
  background-color: var(--bgPrimary-glass);
  font-size: 1.5rem;
  font-weight: 700;
  padding: 13px 16px 16px 13px !important;
}
.override-project-button button:hover{
  transform: scale(0.99);
  color: white !important;
  background-color: transparent !important;
  border-color: var(--PrimaryBlue) !important;
}

.project-play-button{
  position: relative;
  display: inline-flex;
  font-size: 1.4rem;
  font-weight: 500;
  width: 140px;
  margin-right: 21px;
  text-align: center;
  justify-content: center;
  line-height: 1;
  color: black;
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
  padding: 13px 21px 13px 13px !important;
  background: white !important;
  border: white 4px solid;
  backdrop-filter: blur(13px);
  border-radius: 13px;
}
.project-play-button button{
  position: relative;
  display: inline-flex;
  font-size: 1.4rem;
  font-weight: 500;
  width: 100px;
  margin-right: 21px;
  text-align: center;
  justify-content: center;
  line-height: 1;
  color: black !important;
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
  padding: 13px 21px 13px 13px;
  border: white 4px solid;
  backdrop-filter: blur(3px);
  border-radius: 13px;
}

.project-info-button{
   position: relative;
   top: 4px;
   display: inline-flex;
   font-size: 1.6rem;
   font-weight: 400;
    margin: 0 13px 0 auto;
    width: 140px;
   text-align: center;
   justify-content: center;
   line-height: 1;
   color: var(--text-primary);
   transition: all 0.5s ease-in;
   -webkit-transition: all 0.5s ease-in;
   padding: 13px 16px 13px 13px !important;
   background-color: transparent;
   border-radius: 13px;
   border: none  !important;
 }
.project-info-button button{
  position: relative;
  display: inline-flex;
  font-size: 1.6rem;
  font-weight: 400;
  margin: 0 auto;
  width: 100px;
  text-align: center;
  justify-content: center;
  line-height: 1;
  color: var(--text-primary);
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
  padding: 13px 21px 13px 13px;
  border: none !important;
  background-color: transparent;
  border-radius: 13px;
}

.project-play-button:hover{
    transform: scale(0.95);
    color: white !important;
    background-color: var(--bgPrimary-glass) !important;
}

.project-info-button h3{
  font-weight: normal !important;
}

.bottom-fluid{
   display: none;
   writing-mode: vertical-lr !important;
   text-align: right !important;
   bottom: 100px !important;
 }
.player-wrapper {
  display: none;
  position: fixed;
  top: 0;
  background: var(--bgPrimary-glass);
  z-index: -20;
  opacity: 0;
  transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
}
.secondary-project-cta{
  position: absolute;
  font-size: 1rem;
  width: 250px;
  height: 55px;
  top: 80%;
  left: 0;
  right: 0;
  color: rgb(255, 255, 255);
  padding: 2px;
  margin: 0 auto;
  border: none;
  border-radius: 8px;
  background: var(--bgPrimary-glass);
  opacity: 0.77;
  z-index: 9999 !important;
  overflow: hidden;
  cursor: pointer !important;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}
.secondary-project-cta p{
  color: rgb(255, 255, 255);
  position: relative;
  font-size: 1.2rem;
  font-weight: 700;
  padding-right: 4px;
  margin: 0 auto;
  text-align: center;
  vertical-align: middle;
  cursor: pointer !important;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}
.secondary-project-cta:hover{
  color: white !important;
  background-color: var(--PrimaryBlue) !important;
  opacity: 1;
}
.close-button{
  position: absolute;
  font-size: 1rem;
  width: 50px;
  height: 180px;
  top: 44%;
  left: 0;
  color: rgba(255, 255, 255, 1);
  padding: 2px;
  margin: 0 auto;
  border: none;
  border-radius:  0 10px 10px 0;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  background: var(--bgPrimary-glass);
  z-index: 9999 !important;
  overflow: hidden;
  opacity: 0.77;
  cursor: pointer !important;
}
.close-button p{
  color: rgb(255, 255, 255, 0.89);
  position: relative;
  font-size: 1.2rem;
  font-weight: 700;
  padding-right: 4px;
  margin: 0 auto;
  text-align: center;
  vertical-align: middle;
  writing-mode: vertical-lr;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}
.close-button:hover{
  color: white !important;
  background-color: var(--PrimaryRed) !important;
  opacity: 1;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  background: var(--bgPrimary-glass);
  overflow: hidden;

}
.onScreen{
  display: flex;
  position: absolute;
  z-index: 9;
  animation: playerShow 0.5s ease-in forwards;
  -webkit-animation: playerShow 0.5s ease-in forwards;
  overflow: hidden;
}
.offScreen{
  animation: playerHidden 0.5s ease-in forwards;
  -webkit-animation: playerHidden 0.5s ease-in forwards;
}
.doubleClickCara{
  position: relative;
  display: flex;
  font-weight: bold;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 600px;
  font-size: 1rem;
  padding: 21px;
  opacity: 1;
  background: transparent;
  user-select: none;
  -webkit-user-select: none;
  z-index: 1;
}
@keyframes playerHidden {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    z-index: -20 !important;
    display: none;
  }
}
@keyframes playerShow {
  0% {
    opacity: 0;
  }
  100% {
    display: flex;
    opacity: 1;
  }
}
.slab-project-container{
  margin-top: 55px;
  margin-bottom: 0;
}
@media screen and (max-width: 950px){
  .main-project-title h1{
    font-size: 2.5rem;
    margin-bottom: 5px !important;
  }
  .main-project-title p{
    font-size: var(--fontMobileP);
    width: 60%;
    height: auto;
    word-break: break-word;
    margin-bottom: 21px;
  }
  .override-project-button button{
    font-size: 1.4rem;
    font-weight: 600;
    margin-top: 1rem;
  }
  .close-button{
    top: 10% !important;
    width: 34px !important;
    height: 150px !important;
  }
  .close-button p{
    font-size: 0.8rem !important;
  }
  .secondary-project-cta{
    top: 70% !important;
    height: 45px !important;
    width: 190px !important;
  }
  .secondary-project-cta p{
    font-size: 0.8rem!important;
  }
}
@media(max-width: 950px){
  .main-project-title h1{
    font-size: 2.5rem;
    margin-bottom: 5px !important;
  }
  .main-project-title p{
    font-size: var(--fontMobileP);
    width: 60%;
    height: auto;
    word-break: break-word;
    margin-bottom: 21px;
  }
  .override-project-button button{
    font-size: 1.4rem;
    font-weight: 600;
    margin-top: 1rem;
  }
  .close-button{
    top: 10% !important;
    width: 34px !important;
    height: 150px !important;
  }
  .close-button p{
    font-size: 0.8rem !important;
  }
  .secondary-project-cta{
    top: 70% !important;
    height: 45px !important;
    width: 190px !important;
  }
  .secondary-project-cta p{
    font-size: 0.8rem!important;
  }
}
@media(max-width: 814px){
  .sub-project-slab h2{
    font-size: 1.5rem;
  }
  .close-button{
    top: 10% !important;
    width: 34px !important;
    height: 150px !important;
  }
  .close-button p{
    font-size: 0.8rem !important;
  }
  .secondary-project-cta{
    top: 70% !important;
    height: 45px !important;
    width: 190px !important;
  }
  .secondary-project-cta p{
    font-size: 0.8rem!important;
  }
}
@media(max-width: 540px){
  .main-project{
    height: 1210px;
  }
  .main-project-info-container{
    top: 8%;
  }
  .main-project-title{
    padding: 21px;
  }
  .main-project img{
    height: 580px;
  }
  .sub-carousel-pos{
    height: 200px !important;
  }
  .main-project{
    mask-image: none;
  }
  .main-project-title p{
    width: 82%;
  }
  .sub-project-slab img{
    display: flex;
    position: relative;
    width: 80vw;
    height: 190px;
    object-fit: cover;
    -o-object-fit: cover;
    overflow: hidden;
  }
  .section-min-height-projects{
    height: auto !important;
  }
  .close-button{
    top: 10% !important;
    width: 34px !important;
    height: 150px !important;
  }
  .close-button p{
    font-size: 0.8rem !important;
  }
  .secondary-project-cta{
    top: 70% !important;
    height: 45px !important;
    width: 190px !important;
  }
  .secondary-project-cta p{
    font-size: 0.8rem!important;
  }
}
@media(max-width: 420px){
  .sub-project-slab h2{
    font-size: 1.25rem;
  }
  .override-project-button button{
    height: 77px;
    width: 75vw;
    font-size: 1.2rem;
  }
  .close-button{
    top: 10% !important;
    width: 34px !important;
    height: 150px !important;
  }
  .close-button p{
    font-size: 0.8rem !important;
  }
  .secondary-project-cta{
    top: 70% !important;
    height: 45px !important;
    width: 190px !important;
  }
  .secondary-project-cta p{
    font-size: 0.8rem!important;
  }
}
@media(max-width: 375px){
  .override-project-button button{
  }
  .close-button{
    top: 10% !important;
    width: 34px !important;
    height: 150px !important;
  }
  .close-button p{
    font-size: 0.8rem !important;
  }
  .secondary-project-cta{
    top: 70% !important;
    height: 45px !important;
    width: 190px !important;
  }
  .secondary-project-cta p{
    font-size: 0.8rem!important;
  }
}

.section-min-height-projects{
  min-height: 1080px !important;
}
/* Section 4 */
.section4{
  display: flex;
  position: absolute;
  width: 100vw;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  vertical-align: center;
  color: transparent;
  background: transparent;
  z-index: 1;
}
/* FORM */
.polaris-text{
  font-family: Monoton, Montserrat, serif;;
  font-weight: normal;
  font-size: 1.3rem;
}
.polaris-text-ext{
  font-size: 1.3rem;
}
.subscript-ext{
  font-size: 0.7rem;
  top: -3px;
  position: relative;
  vertical-align: bottom;
  padding-left: 2px;
}
.form-container{
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 1080px;
  top: 0;
  padding-top: 34px;
  padding-bottom: 89px;
}
.form-container h2{
  vertical-align: center;
  text-align: center;
  padding: 21px 20px 20px 20px;
  margin: 34px auto 0 auto;
  font-weight: 600;
  width: 600px;
  max-width: 90vw;
  word-break: break-word;
  font-size: 1.5rem;
  line-height: 1.5;
}
.form-container h3{
  vertical-align: center;
  text-align: center;
  padding: 21px 20px 20px 20px;
  margin: 0 auto;
  font-weight: 500;
  width: 600px;
  max-width: 90vw;
  word-break: break-word;
  font-size: 1.5rem;
  line-height: 1.5;
}
.office-hours{
  font-size: 1.3rem;
  font-weight: 400 !important;
  color: rgb(255, 255, 255, 0.8);
}
.padding-form-alt{
  padding: 8px !important;
  margin: 0 auto !important;
}

.row-form{
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto 0;
  padding-top: 13px;
  max-height: 100%;
  max-width: 100vw;
  background-color: transparent;
  overflow: hidden;
}
.row-form img{
   position: relative;
   flex-direction: column;
   width: 300px;
   height: auto;
   padding-left: 13px;
   padding-top: 21px;
   padding-bottom: 34px;
   margin: 0 auto;
   left: 0;
   right: 0;
   align-content: center;
   align-items: center;
   align-self: center;
 }
.row-form h3{
  position: relative !important;
  font-size: 1.2rem !important;
  font-family: Dubai, Montserrat, sans-serif;
  font-weight: 400;
  padding: 0 0 0 0;
  margin: 0 9px 55px 0;
  text-align: center;
  width: 100%;
  max-width: 100%;
  color: rgba(255, 255, 255, 0.89) !important;
  letter-spacing: .1em;

}

.row-form p{
  position: relative;
  font-size: 2.4rem;
  padding: 21px
}
.row-form i{
  position: relative;
  font-size: 3.5rem;
  padding: 21px
}
.row-form a{
  margin: 8px;
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
  filter: drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.3));
}
.row-form a:hover{
  transform: scale(0.9) translateY(4px);
}
.form-button i{
  font-size: 1.5rem !important;
}
.form-button p{
  font-size: 1.8rem !important;
}
.form-button button{
  display: flex;
  height: 89px;
  width: 90vw;
  max-width: 500px;
  justify-content: center;
  align-items: center;
  transition: all 0.33s ease-in !Important;
  -webkit-transition: all 0.33s ease-in !important;
  vertical-align: middle;
  flex-direction: column;
  margin: 1px auto 1px auto !important;
  padding: 2px;
  border-radius: 10px;
  border: 4px solid rgb(245, 245, 245);
  color: rgb(255, 255, 255);
  background-color: rgb(10, 10, 10, 0);
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
}
.whatsapp-button{
  background-color: var(--PrimaryGreen) !important;
  border: 4px solid var(--PrimaryGreen) !important;
  transition: all 0.33s ease-in !Important;
  -webkit-transition: all 0.33s ease-in !important;
}
.call-button{
  background-color: var(--PrimaryBlue) !important;
  border: 4px solid var(--PrimaryBlue) !important;
  transition: all 0.33s ease-in !Important;
  -webkit-transition: all 0.33s ease-in !important;
}
.calendly-button{
  background-color: white !important;
  color: var(--PrimaryBlue) !important;
  border: 4px solid White !important;
  transition: all 0.33s ease-in !Important;
  -webkit-transition: all 0.33s ease-in !important;
}
.calendly-button p{
  color: var(--PrimaryBlue) !important;
}
.scorecard-button{
  background-color: white !important;
  color: black !important;
  border: 4px solid White !important;
  transition: all 0.33s ease-in !Important;
  -webkit-transition: all 0.33s ease-in !important;
}
.scorecard-button p{
  color: black !important;
}

.insta-button{
  border: 4px solid rgb(255, 255, 255) !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  transition: all 0.33s ease-in !Important;
  -webkit-transition: all 0.33s ease-in !important;
}
.instagram-button{
  border: 4px solid White !important;
  background-color: Black !important;
  transition: all 0.33s ease-in !Important;
  -webkit-transition: all 0.33s ease-in !important;
}
.email-button{
  background-color: White !important;
  color: black !important;
  border: 4px solid White !important;
  transition: all 0.33s ease-in !Important;
  -webkit-transition: all 0.33s ease-in !important;
}
.email-button p{
  color: black !important;
}
.contact-sub-text{
  font-size: 1.3rem !important;
  font-weight: 500;
  text-align: center;
  color: rgb(255, 255, 255, 0.8);

}
.qr-code{
  margin: 0 auto;
  left: 0;
  right: 0;
  align-content: center;
  alignment: center;
  padding: 13px;
}
.qr-code img{
  width: 210px;
  height: 210px;
  border-radius: 21px;
  border: 4px solid rgba(0, 0, 0, 0.2);
}
.contact-form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  width: 95vw;
  max-width: 420px;
  min-height: 600px;
  padding: 0;
  margin: 0;
  color: white;
  background-color: transparent;
  overflow: clip;
}
.contact-form label{
  display: flex;
  font-size: 1.3rem;
  flex-direction: column;
  justify-content: left;
  width: 420px;
  max-width: 95vw;
  font-weight: 500;
  height: 100%;
  text-align: left;
  margin: 34px auto 13px auto;
  color: white !important;
  background-color: transparent;
  overflow: hidden;
}
.contact-form input{
  display: flex;
  font-size: 1.3rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  width: 95vw;
  max-width: 420px;
  height: 100%;
  padding: 0;
  margin: 0 auto;
  border-bottom: white 2px solid;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  color: white !important;
  background-color: transparent;
  overflow: hidden;
}
.contact-form input:focus{
  outline: none;
}
.contact-form ::placeholder{
  color: rgba(255, 255, 255, 0.65) !important;
  font-size: 1.2rem;
  padding-left: 5px;
}
.contact-form input:invalid{
  border-bottom: rgb(255, 255, 255, 0.6) 2px solid;
}
.contact-form input:valid{
  border-bottom: rgba(0, 0, 0, 0) 2px solid;
}
.contact-form textarea{
  display: flex;
  font-size: 1.3rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  width: 420px;
  max-width: 95vw;
  height: 60px;
  border: rgba(255, 255, 255, 0) 2px solid;
  color: white !important;
  background-color: var(--bgPrimary-glass);
  user-select: text;
  padding: 1px;
  border-radius: 8px;
  resize: none;
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
}
.contact-form textarea:focus{
  height: 120px !important;
  outline: none !important;
  border: none !important;
}
.contact-form textarea:hover{
  height: 120px !important;
  outline: none !important;
  border: none !important;
}
.contact-form select{
  font-size: 1.3rem;
  width: 420px;
  max-width: 95vw;
  padding: 8px;
  border: none;
  border-radius: 0;
  margin: 13px auto 0 auto;
  color: white !important;
  background-color: var(--bgPrimary-glass);
  overflow: hidden;
  cursor: pointer;
}
.contact-form option{
  background-color: #0a0a0a !important;
  color: var(--text-primary);
  border: none transparent !important;
}
.contact-form input{
    font-size: 1.3rem;
    width: 420px;
    max-width: 95vw;
    padding: 8px;
    border: none;
    border-radius: 0;
    margin: 0 auto;
    color: white !important;
    background-color: var(--bgPrimary-glass);
    overflow: hidden;
}
.submit-form{
  width: 500px;
  max-width: 95vw;
  overflow: hidden;
}
.submit-form-button{
  display: inline-block;
  justify-content: space-around;
  height: 55px;
  width: 420px;
  max-width: 95vw;
  align-items: center;
  alignment: center;
  align-content: center;
  align-self: center;
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
  flex-direction: row;
  margin: 8px auto 0 auto !important;
  border-radius: 10px;
  background-color: var(--PrimaryGreen);
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  overflow: hidden;
}
.invalid-button-border{
  border: 4px solid rgba(245, 245, 245, 0.6) !important;
  color: rgba(255, 255, 255, 0.6) !important;
  background-color: transparent;
}
.valid-button-border{
  border: 4px solid var(--PrimaryGreen) !important;
  color: rgb(255, 255, 255) !important;
}
.submit-form-button:hover{
  transform: scale(0.95);
}
.submit-form-button-disabled-transform:hover{
  transform: none !important;
}
.submit-form-button i{
  display: inline-block;
   opacity: 1;
   font-size: 1.5rem !important;
   text-align: right;
   padding: 0;
   margin: 0 auto;
  vertical-align: middle;
   color: white !important;
   background-color: transparent;
   overflow: hidden;
   transition: all 0.5s ease-in;
   -webkit-transition: all 0.5s ease-in;
 }
.submit-form-button:hover i{
  transform: translateX(20px);
}
.submit-form-button p{
   display: inline-block;
   font-size: 1.5rem;
   text-align: center;
  vertical-align: middle;
  margin:0 auto;
   color: white !important;
   background-color: transparent;
   overflow: hidden;
 }
.submit-policy-text{
  margin: 0 auto;
  padding: 0;
}
.submit-policy-text i{
    font-size: 0.9rem;
    opacity: 1;
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
}
.down-arrow-accent-form i{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  color: white;
  background-color: transparent;
  overflow: hidden;
  font-size: 5rem;
}
@media(min-width: 840px) {
  .row-form img{
    width: 400px;
  }
  .row-form h3{
    font-size: 1.3rem !important;
  }
}
@media(max-width: 840px){
  .remove{
    display: none;
  }
}
@media(max-width: 600px) {
  .form-container {
    padding-bottom: 21px;
  }
  .form-container h2{
    font-size: 1.3rem;
  }
  .form-container h3{
    font-size: 1rem;
  }
  .form-button button{
    font-size: var(--fontMobileP);
    font-weight: var(--fontMobile-Weight-Semibold);
    max-width: 85vw !important;
    height: 55px !important;
    line-height: 0 !important;
  }
  .row-form img{
    width: 50vw;
  }
  .row-form h3{
    font-size: 0.9rem !important;
  }
  .form-button p {
    font-size: 21px !important;
    font-weight: var(--fontMobile-Weight-Semibold);
  }
  .row-form p{
    font-size: 21px !important;
    font-weight: var(--fontMobile-Weight-Semibold);
  }
  .row-form i{
    font-size: 3rem !important;
    font-weight: var(--fontMobile-Weight-Semibold);
  }
  .polaris-text{
    font-size: 1rem !important;
  }
  .office-hours{
    font-size: 1rem;
  }
  .contact-sub-text{
    font-size: 1rem !important;
  }
}
@media(max-width: 420px){
  .row-form img{
    width: 280px;
  }

  .office-hours{
    font-size: 0.8rem;
  }
  .contact-sub-text{
    font-size: 0.8rem !important;
  }
  .polaris-text{
    font-size: 1.1rem !important;
  }
  .polaris-text-ext{
    font-size: 1.1rem !important;
  }
  .contact-form label{
    font-size: 1.1rem;
  }
  .contact-form ::placeholder{
    font-size: 1rem;
    padding-left: 3px;
  }
  .form-container{
    padding-top: 0;
    padding-bottom: 89px;
  }
}
/* Section WhyUs */
.section5-padding-override{
  padding: 34px 0 55px 0 !important;
}
.section5{
  display: flex;
  position: relative;
  min-width: 100vw;
  min-height: 1400px;
  padding: 140px 0 55px 0;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  vertical-align: center;
  color: var(--text-primary);
  background: transparent;
  z-index: 3;
}
.section5-tiers{
  height: 100%;
  display: grid;
  width: 90vw;
  max-width: 600px;
  gap: 2rem;
  padding-top: 40px;
  margin: 100px auto 100px;
}
.tier-item {
  max-height: 90vh;
  max-width: 90vw;
  margin: 0 auto;
  padding: 0 !important;
  position: relative;
  display: inline-grid;
  flex-direction: column;
}
.tier-item h2 {
  font-size: 2.5rem;
  padding: 0;
  font-weight: bold;
  text-align: left;
  alignment: left;
  margin: 0 auto 1rem 1rem;
}
.tier-item h3 {
  font-size: 1.5rem;
  padding: 0;
  font-weight: bold;
  text-align: left;
  alignment: left;
  margin: 0 auto 0.5rem 1rem;
  color: rgba(255, 255, 255, 0.87);
}
.tier-item p {
  color: rgb(255, 255, 255, 0.7);
  font-size: var(--fontDesktopP);
  padding: 2px;
  font-weight: 500;
  width: 95%;
  margin: 0.55rem auto 0 1rem;
  text-align: left;
  alignment: left;
  word-break: break-word;
}
.tier-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  border-radius: 10px;
  margin: 0;
  scale: 0.96;
}
.spacer-1rem-bottom{
  margin-bottom: 1.25rem !important;
}
@media (max-width: 700px) {
  .section5-tiers{
    margin: 20px auto 20px;
  }
  .tier-item{
    max-width: 100% !important;
    padding: 0 !important;
  }
}
/* PROGRESS BARS */
.progress-bar-container{
  position: relative;
  padding-top: 13px;
  width: 100%;
  max-width: 90vw;
  margin: 0 auto 1rem 1rem;
  padding-bottom: 21px;
  background-color: transparent !important;
}
.progress-bar-container h3{
  font-size: 1.3rem;
  font-weight: 500 !important;
  width: 85vw;
  max-width: 560px;
  color: rgb(255, 255, 255, 0.8);
  background-color: transparent !important;
}
.progress-bar{
  font-weight: 600;
  text-align: right;
  padding: 5px;
  color: black;
  background: rgb(255, 255, 255, 0.9) !important;
  border-radius: 8px !important;
  margin-bottom: 21px;
}
@media (max-width: 600px) {
  .progress-bar-container h3 {
    font-size: 0.9rem;
  }
  .progress-bar-container {
    margin: 13px;
    padding: 0;
  }
  .section5-tiers{
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    gap: 2rem;
  }
  .tier-item{
    padding: 0.5rem;
  }
  .tier-item h2 {
    font-size: 2rem;
    padding: 2px;
    text-align: left;
    alignment: left;
    margin: 0.5rem;
  }
  .tier-item h3{
    font-size: 1.25rem;
    margin: 8px;
    padding: 2px;
  }
  .tier-item p {
    font-size: var(--fontMobileP);
    padding: 2px;
    font-weight: 500;
    alignment: left;
    margin: 0.5rem;
    text-align: left;
    word-break: break-word;
  }
}
@media (max-width: 420px) {
  .progress-bar-container h3 {
    font-size: var(--fontMobileP);
  }
}
/* CONTACT US */
.section-contact-us{
  display: flex;
  position: relative;
  min-width: 100vw;
  min-height: 100vh;
  padding: 140px 0 34px 0;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  vertical-align: center;
  background: var(--bgPrimary-glass);
  color: var(--text-primary);
  z-index: 3;
  transition: all 0.5s ease-in;
}
.section-why{
  display: flex;
  position: relative;
  min-width: 100vw;
  min-height: 100vh;
  padding: 140px 0 34px 0;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  vertical-align: center;
  background: transparent;
  color: var(--text-primary);
  z-index: 3;
  transition: all 0.5s ease-in;
}
.social-animation{
  margin-left: -1rem;
  width: 100% !important;
  height: auto !important;
  overflow: hidden !important;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.graph-animation{
  width: 100% !important;
  height: auto !important;
}
.main-flex-tiers {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100vw, 1fr));
  gap: 2rem;
}
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer-social h2{
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
}
.footer-social h2:hover {
  transform: scale(0.95);
}
.footer-tier {
  padding: 1rem;
  position: relative;
  display: inline-grid;
  alignment: center;
    justify-content: center;
  text-align: center;
  flex-direction: column;
}

.footer{
  display: flex;
  position: relative;
  min-width: 100vw;
  min-height: 30vh;
  padding: 140px 0 140px 0;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  vertical-align: center;
  background: var(--bgPrimary);
  color: var(--text-primary);
  z-index: 3;
  transition: all 0.5s ease-in;
}
.footer h2{
  display: flex;
  position: relative;
  flex-direction: column;
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
}
.footer p{
  color: rgba(255, 255, 255, 0.8);
  font-size: var(--fontDesktopP);
  padding: 2px;
  font-weight: 500;
  alignment: left;
  margin: 1rem;
  text-align: center;
  word-break: break-word;
}
.footer-logo h3{
  font-size: 1.3rem !important;
  font-weight: 400 !important;
  color: rgb(255, 255, 255, 0.8);
  text-align: center;
  padding-top: 8px;
  margin-left: -6px;
  font-family: Dubai, Montserrat, sans-serif;
  letter-spacing: .1em;
  text-transform: uppercase;
}
.footer-logo{
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100vw;
  height: 250px;
  margin: 10px auto 10px;
  text-align: center;
  alignment: center;
  align-content: center;
  justify-content: center;
  vertical-align: center;
  background: var(--bgPrimary);
  color: var(--text-primary);
  z-index: 3;
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
}
.footer-logo img{
  position: relative;
  left: 0;
  right: 0;
  text-align: center;
  padding-left: 15px;
  margin: 0 auto;
  align-content: center;
  justify-content: center;
  width: 420px;
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
}

@media(max-width: 2560px) {
  .footer-logo h3 {
    font-size: 1.2rem !important;
  }
  .footer-logo img{
    width: 350px;
    height: auto;
  }
}
@media(max-width: 800px) {
  .footer-logo h3 {
    font-size: 1rem !important;
  }
  .footer-logo img{
    width: 350px;
    height: auto;
  }
}
@media(max-width: 600px) {
  .footer-logo h3 {
    font-size: 1rem !important;
  }
  .footer-logo img{
    width: 300px;
    height: auto;
  }
}
@media(max-width: 420px) {
  .footer-logo img{
    width: 250px;
    height: auto;
  }
  .footer-logo h3{
    font-size: 0.8rem !important;
  }
}
.footer-links{
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
}
.footer-social{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
}
.footer-links li{
  margin: 0 10px;
  list-style-type: none;
  text-transform: uppercase;
}
.footer-social li{
  margin: 0 10px;
  list-style-type: none;
}
.footer-links a{
  color: var(--text-primary);
  font-size: 1.5rem;
  text-decoration: none;
  transition: color 0.3s ease;
}
.footer-social a{
  color: var(--text-primary);
  font-size: 2.5rem !important;
  text-decoration: none;
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
}
.footer-links a:hover{
  transform: scale(0.95);
}
.footer-social a:hover{
  transform: scale(0.9);
}
.footer-social-buttons{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  list-style: none;
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
}
.footer-social-buttons a{
  color: var(--text-primary);
  font-size: 3.5rem;
  font-weight: 900;
  padding: 13px;
  margin: 13px;
  text-decoration: none;
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
}
.footer-social-buttons a:hover{
  transform: scale(0.9) !important;
}
.footer-social-buttons i{
  color: var(--text-primary);
  font-size: 2.9rem;
  font-weight: 700;
line-height: 0.8;
  margin: 13px;
  text-decoration: none;
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
}
.footer-social-buttons i:hover{
  transform: scale(0.9) !important;
}

.footer-site-text{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  list-style: none;
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
}
.footer-site-text a{
  color: var(--text-primary);
  font-size: 0.89rem !important;
  font-weight: 700;
  padding: 13px;
  margin: 13px;
  text-decoration: none;
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
}
.footer-site-text a:hover{
  transform: scale(0.95) !important;
}

.footer-tier a:hover{
  transform: scale(0.9);
}
.studio-pancake-logo-footer img{
  width: 280px !important;
}
.footer p{
  font-weight: 700 !important;
}

.section-why h3{
  margin: 3rem auto 0 auto;
  text-align: left;
  padding: 0.34rem;
  font-size: 1.25rem;
  max-width: 1200px;
  width: 100%;
}
/* Services BUTTONS */
.button-service-row{
  margin-top: 4rem;
  margin-bottom: 0.34rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.border-highlighted button{
  color: white !important;
  border: 4px solid white !important;
  border-radius: 8px;
}
.button-no-fill button{
  font-size: 2.5rem;
  background: none;
  backdrop-filter: blur(20px);
  border: 4px solid rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.77);
  font-weight: 700;
  padding: 1rem 8rem;
  margin: 0 auto;
  width: auto;
  height: auto;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}
.button-no-fill :hover{
  border: 4px solid white !important;
}
.button-no-fill :active{
  border: 4px solid white !important;
}
.button-no-fill :focus{
  border: 4px solid white !important;
}
@media (max-width: 3440px) and (max-height: 1440px){
  .button-no-fill button{
    font-size: 1.5rem;
    padding: 1rem 4rem;
  }
}
@media (max-width: 2560px){
  .section-why h3{
    font-size: 1rem;
    max-width: 1000px;
  }
  .button-no-fill button{
    font-size: 1.5rem;
    padding: 1rem 4rem;
  }
}
@media (max-width: 1620px){
  .section-why h3{
    max-width: 50%;
    font-size: 0.89rem;
  }
}
@media (max-width: 1140px){
  .section-why h3{
    max-width: 66%;
  }
}
@media (max-width: 840px){
  .section-why h3{
    max-width: 80%;
  }
}
@media(max-width: 640px){
  .button-no-fill button{
    font-size: 1.25rem;
    padding: 1rem 3rem;
  }
  .section-why h3{
    max-width: 80%;
    font-size: 0.77rem;
  }
}
@media(max-width: 480px){
  .button-no-fill button{
    font-size: 1rem;
    padding: 1rem 2rem;
  }
}
@media(max-width: 360px){
  .button-no-fill button{
    font-size: 0.89rem;
    padding: 1rem 1.5rem;
  }
}
/* FLEX BOXES GRIDS */
.services-container{
    display: flex;
    position: relative;
    max-width: 84vw;
    min-height: 100vh;
    padding: 5px;
    margin: 1rem auto 1rem auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    vertical-align: center;
    background: transparent;
    color: var(--text-primary);
    z-index: 3;
    transition: all 0.5s ease-in;
}

.row {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
/* Create equal columns */
.column{
  flex: 30%;
  display: flex;
  flex-direction: column;
  min-height: 280px;
  width: 600px;
  max-width: 100vw;
  margin: 1rem;
  padding: 4px;
  background: transparent;
  align-content: center;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
  border-radius: 30px !important;
}
.column i{
  width: 240px;
  height: 60px;
  font-size: 3.5rem;
  margin: 0 auto;
  padding: 1px;
  text-align: center;
  vertical-align: middle;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}
.column h2 {
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
  padding: 0;
  color: var(--text-primary);
  font-size: var(--fontDesktopH4);
}
.column h4{
  font-weight: 500;
  text-align: center;
  margin: 0 auto;
  padding: 3px;
  color: var(--text-primary);
  font-size: 1.25rem;
}
.column p{
  width: 100%;
  max-width: 500px;
  color: var(--text-secondary);
  font-size: var(--fontDesktopPSmall);
  margin: 0 auto;
  line-height: 1.5;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.column:hover{
  transform: scale(1.05);
}
.column i:hover{
  color: rgb(255, 255, 255) !important;
}
.column-button-override{
}
.ReactCollapse--collapse {
  transition: height 500ms ease-in-out;
}
@media screen and (max-width: 1300px){
  .column {
    flex: 30%;
  }
  .column h4{
    font-size: 1rem !important;
  }
  .column p{
    font-size: var(--fontMobileP);
  }
}
@media screen and (max-width: 840px) {
  .services-container{
    max-width: 94vw;
  }
  .column h4{
    font-size: 0.89rem !important;
  }
}
@media (max-width: 420px) {
  .footer-social-buttons a{
    font-size: 2.5rem;
    font-weight: 900;
    padding: 8px;
    margin: 8px;
  }
  .footer-social-buttons i{
    font-size: 2.5rem;
    font-weight: 900;
    padding: 8px;
    margin: 8px;
  }
  .column i{
    font-size: 3rem;
  }
  .skill-bars .bar .progress-line{
    height: 1.5rem !important;
    border-radius: 5px !important;
  }
  .bar .progress-line span {
    border-radius: 5px !important;
  }
  .column{
    min-height: 190px !important;
    padding: 0;
    margin: 0 auto;
  }
  .column h4{
    font-size: 0.77rem !important;
  }
  .column p{
    font-size: 0.75rem;
    width: 100% !important;
    max-width: 90vw !important;
  }
}
/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .row {
    flex-direction: column;
  }
  .column{
    padding: 1rem;
    margin: 0 auto;
    max-width: 88vw;
  }
}
@media(max-width: 600px){
  .section5-tiers{
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    gap: 2rem;
  }
  .tier-item{
    padding: 0.5rem;
  }
  .tier-item h2 {
    font-size: 1.5rem;
    padding: 2px;
    text-align: left;
    alignment: left;
    margin: 0.5rem;
  }
  .tier-item p {
    color: rgba(255, 255, 255, 0.8);
    font-size: var(--fontMobileP);
    padding: 2px;
    font-weight: 500;
    alignment: left;
    margin: 0.5rem;
    text-align: left;
    word-break: break-word;
  }
}

/* 4K SCREENS */
@media(min-width: 1700px) and (min-height: 1300px){
  /* Section 0 */
  .hero-title-main{
    position: absolute;
    top: 32%;
    background: transparent;
    max-width: 100%;
    bottom: -0.5rem !important;
    left: 0;
    right: 0;
    text-align: center;
    align-items: center;
    font-weight: 900;
    color: rgba(255, 255, 255, 0.55);
    margin: 0 auto;
    font-size: 6rem !important;
    z-index: 2;
    overflow: hidden;
  }
  .hero-title-main h3{
    font-size: 1.5rem !important;
    margin-top: 3rem !important;
    margin-bottom: -3.75rem !important;
  }
  .navbar-title img{
    image-rendering: -webkit-crisp-edges;
    object-fit: cover;
    -o-object-fit: cover;
    width: 16rem; /* or max-width: 100%; */
    height: auto;
    padding: 6px 2px 2px;
  }
  .navbar-item{
    padding: 1.5rem 1.5rem;
  }
  .navbar-item-last{
    padding: 1.5rem 1.5rem;
  }
  .contact-button{
    position: relative;
    right:-10px;
    height: 25rem;
    width: 8rem;
    z-index: 10;
    margin: 20px auto;
    color: rgb(255, 255, 255, 1);
    background-color: transparent;
    font-weight: 600;
    text-decoration: none !important;
    border: rgba(255, 255, 255, 0) 2px solid;
    border-radius: 8px;
    border-right: none;
    overflow: hidden;
    transition: all 0.5s ease;
    writing-mode: vertical-lr;
  }
  .contact-button i{
    text-transform: uppercase;
    font-size: 4rem;
    padding-bottom: 2px;
    vertical-align: center;
    writing-mode: vertical-lr;
    text-align: center;
    font-weight: 600;
    line-height: 0;
    color: rgb(255, 255, 255, 1);
  }
  .tier-item-top-margin{
    padding-top: 89px !important;
    margin-top: 144px !important;
  }
  .button-no-fill button{
    font-size: 2.5rem;
    padding: 1rem 8rem;
  }
  /* Section 4 */
  .services-container{
    display: flex;
    position: relative;
    max-width: 84vw;
    min-height: 100vh;
    padding: 5px 5px 0 5px;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    vertical-align: center;
    background: transparent;
    color: var(--text-primary);
    z-index: 3;
    transition: all 0.5s ease-in;
  }
  .row {
    margin-top: 60px;
    margin-bottom: 60px;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  /* Create equal columns */
  .column{
    flex: 30%;
    display: flex;
    flex-direction: column;
    min-height: 280px;
    width: 890px;
    max-width: 100vw;
    margin: 1rem;
    padding: 20px;
    align-content: center;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
    border-radius: 4px;
  }
  .column i{
    width: 890px;
    height: 120px;
    font-size: 6.5rem;
    margin: 0 auto;
    padding: 10px;
    text-align: center;
    vertical-align: middle;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
  }
  .column h2 {
    width: 890px;

    font-weight: bold;
    text-align: center;
    margin: 0 auto;
    padding: 0;
    color: var(--text-primary);
    font-size: 3rem;
  }
  .column p{
    width: 850px;
    max-width: 90vw;
    color: var(--text-secondary);
    font-size: 1.5rem;
    margin: 0.55rem auto;
  }

  .quote-button button{
    height: 120px !important;
  }
  /* Section 1 SLABS */
  .slab1 img{
    width: 60rem !important;
    height: 20rem !important;
  }
  .slab1 h2{
    font-size: 2.5rem !important;
  }
  /* Section 2 VIDEO SECTION */
  .videoSection h1{
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 5rem;
    font-weight: 800;
    margin: 0 auto 1rem auto;
  }
  .videoSection p {
    position: relative;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    align-content: center;
    align-items: center;
    align-self: center;
    color: rgb(255, 255, 255, 0.89) !important;
    left: 0;
    right: 0;
    width: 90%;
    max-width: 1100px;
    font-size: 2.5rem;
    margin: 0 auto 6rem;
  }

  .container-fluid > .text {
    font-size: 10rem;
    font-weight: 900;
  }
  .container-fluid-sticky > .text-sticky {
    font-size: 10rem;
  }
  .container-fluid-sticky img{
    width: 7rem;
    image-rendering: high-quality;
  }
  /* Section 2 TRUSTED BY */
  .row-logos{
    scale: 1.7;
  }
  .section-trusted-by-container h3{
    font-size: 2rem !important;
    padding: 4px;
    font-weight: bold;
    text-align: center;
    alignment: center;
    margin: 0 auto 1.5rem auto;
  }
  .section-trusted-by-container {
    opacity: 1;
    display: flex;
    position: absolute;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    height: 550px;
    width: 90vw;
    padding: 0;
    max-width: 46.875rem;
    color: var(--text-primary);
    background: rgb(255, 255, 255, 0);
    z-index: 3;
  }
  .slab5-project i{
    font-size: 2rem !important;
  }
  .slab5-project button{
    display: flex;
    height: 121px !important;
    width: 90vw;
    max-width: 46.875rem;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
    vertical-align: middle;
    flex-direction: column;
    margin: 89px auto 55px !important;
    padding: 10px;
    border-radius: 10px;
    font-size: 2.5rem !important;
    font-weight: 700;
    text-align: center;
  }
  .button-text-row{
    font-size: 4rem !important;
  }
  .button-text-row p{
    font-size: 3.5rem !important;
  }
  /* Section 3 POWERED BY */
  .section-powered-by{
    min-height: 550px !important;
    }
  /* Section 3 POWERED BY */
  .main-project-section-override{
    min-height: 2000px !important;
  }
  .section-project{
    min-height: 2160px !important;
  }
  .main-project{
    position: absolute;
    top: 0;
    width: 100vw;
    min-height: 2160px;
    background: transparent;
    mask-image: linear-gradient(to right, #0a0a0a 66%, transparent 100%);
  }
  .main-project-bottom-buttons{
    position: absolute;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    alignment: center;
    align-items: center;
    align-content: center;
    bottom: 0 !important;
    vertical-align: center;
  }
  .main-project-bottom-buttons h2{
    font-size: 3.5rem;
    font-weight: 700;
    color: #f5f5f5;
    margin: 0;
    padding: 0;
  }
  .main-project-bottom-buttons h3{
    text-align: left;
    font-size: 0.9rem;
    font-weight: 400;
    color: #f5f5f5;
    margin: 0 auto;
    padding-top: 13px;
    padding-bottom: 8px;
  }
  .main-project-bottom-buttons p{
    font-size: 1rem;
    font-weight: 400;
    color: #f5f5f5;
    margin: 0;
    padding: 13px;
  }
  .main-project img{
    object-fit: cover;
    -o-object-fit: cover;
    width: 100vw;
    height: 55rem;
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgb(0,0,0,1)), to(rgb(10,10,10,0)));
    mask-image: -webkit-gradient(linear, left top, left bottom, from(rgb(0,0,0,1)), to(rgb(10,10,10,0)));
  }
  .main-project-info-container{
    top: 10%;
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: left;
    width: 100vw;
    height: 1300px;
    background: transparent;
  }
  .main-project-title{
    position: relative;
    width: 100vw;
    background: transparent;
    padding: 34px;
  }
  .main-project-title h1{
    font-size: 5.5rem;
    text-align: left;
    padding: 0;
    margin: 0 auto;
  }
  .main-project-title p{
    font-size: 1.7rem;
    width: 900px;
    height: 130px;
    word-break: break-word;
    left: 0;
    alignment: left;
    padding: 2px;
  }
  .sub-carousel-pos{
    position: relative;
    top: 200px !important;
    height: 500px !important;
    cursor: grab;
  }
  .sub-project-container{
    position: absolute;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    top: 450px;
    left: 0;
    right: 0;
    width: 100vw;
    height: 240px;
    background-color: transparent !important;
    overflow: auto;
    overflow-y: hidden;
    overflow-scrolling: touch;
  }
  .sub-project-slab{
    transition: all 0.5s ease-in;
    display: flex;
    position: relative;
    flex-direction: column;
    top: 0;
    bottom: 0;
    padding: 0;
    width: 300px;
    height: 300px;
    vertical-align: middle;
    align-items: center;
  }
  .sub-project-slab:hover{
    transform: scale(0.95);
  }
  .sub-project-slab h2{
    position: absolute;
    display: flex;
    top: 40%;
    height: auto;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    margin-left: 150px;
    padding: 13px;
    font-size: 2.1rem;
    border-radius: 10px 10px 10px 10px;
    background-color: transparent;
  }
  .sub-project-slab img{
    display: flex;
    position: relative;
    width: 620px;
    height: 300px;
    object-fit: cover;
    -o-object-fit: cover;
    margin: 0 auto;
    border: transparent 4px solid;
    border-radius: 13px;
    background-color: transparent !important;
    -webkit-mask-image: none;
    user-select: none;

  }
  .project-play-button{
    position: relative;
    display: inline-flex;
    font-size: 2.5rem;
    font-weight: 600;
    width: 240px;
    margin-top: 2rem;
    margin-right: 21px;
    margin-left: 5px;
    text-align: center;
    justify-content: center;
    line-height: 1;
    color: black;
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
    padding: 13px 21px 13px 13px !important;
    background: white !important;
    border: white 4px solid;
    backdrop-filter: blur(13px);
    border-radius: 13px;
  }
  .project-info-button{
    position: relative;
    display: inline-flex;
    font-size: 2.5rem;
    font-weight: 400;
    margin: 0 auto;
    width: 210px;
    text-align: center;
    justify-content: center;
    line-height: 1;
    color: var(--text-primary);
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
    padding: 13px 16px 13px 13px !important;
    background-color: var(--bgPrimary-glass);
    backdrop-filter: blur(13px);
    border-radius: 13px;
    border: none  !important;
  }
  .project-play-button button{
    position: relative;
    display: inline-flex;
    font-size: 1.4rem;
    font-weight: 500;
    width: 100px;
    margin-right: 21px;
    text-align: center;
    justify-content: center;
    line-height: 1;
    color: black !important;
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
    padding: 13px 21px 13px 13px !important;
    border: white 4px solid;
    backdrop-filter: blur(3px);
    border-radius: 13px;
  }
  .project-info-button button{
    position: relative;
    display: inline-flex;
    font-size: 1.6rem;
    font-weight: 400;
    margin: 0 auto;
    width: 100px;
    text-align: center;
    justify-content: center;
    line-height: 1;
    color: var(--text-primary);
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
    padding: 13px 21px 13px 13px;
    border: none !important;
    background-color: transparent;
    border-radius: 13px;
  }
  .override-project-button{
    width: 100% !important;
    height: auto !important;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .override-project-button button{
    height: 121px;
    width: 90vw;
    max-width: 550px;
    font-size: 2rem;
  }
  .override-project-button button:hover{
    transform: scale(0.99);
    color: white !important;
    background-color: transparent !important;
    border-color: var(--PrimaryBlue) !important;
  }
  /* Section 3 POWERED BY */
  .section5-tiers{
    height: 100%;
    display: grid;
    width: 100%;
    max-width: 1200px;
    gap: 2rem;
    padding-top: 40px;
    margin: 100px auto 100px;
  }
  .tier-item {
    max-height: 90vh;
    width: 100%;
    max-width: 90vw;
    left: 0 !Important;
    right: 0 !important;
    align-content: center;
    margin: 0 auto;
    position: relative;
    flex-direction: column;
    text-align: center !important;
    background: transparent !important;
  }
  .tier-item h2 {
    font-size: 3.5rem;
    width: 100%;
    font-weight: bold;
    position: relative;
    text-align: left;
  }
  .tier-item h3 {
    font-size: 2.5rem;
    width: 100%;
    font-weight: bold;
    position: relative;
    text-align: left;
  }
  .tier-item p {
    font-size: 2rem;
    padding: 2px;
    font-weight: 500;
    alignment: left;
    margin: 0.55rem auto 0 1rem;
    text-align: left;
    word-break: break-word;
  }
  .tier-item-adjust-pos{
    margin-left: -3rem;
  }
  .slab5-project-why i{
    font-size: 2rem !important;
  }
  .slab5-project-why button{
    display: flex;
    height: 121px !important;
    width: 100vw !important;
    max-width: 72rem !important;
    justify-content: center;
    align-items: center;
    transition: all 0.33s ease-in;
    -webkit-transition: all 0.33s ease-in;
    vertical-align: middle;
    flex-direction: column;
    margin: 89px auto 55px;
    padding: 10px;
    border-radius: 10px;
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
  }

  .progress-bar-container{
    position: relative;
    margin: 0 auto;
    padding-top: 55px;
    width: 100vw;
    max-width: 72rem !important;
    padding-bottom: 21px;
  }
  .progress-bar-container h3{
    font-size: 2rem;
    width: 100vw;
    max-width: 72rem !important;
    color: rgb(255, 255, 255, 0.8);
  }
  .progress-bar{
    font-weight: 600;
    text-align: right;
    padding: 5px;
    color: black !important;
    background: rgb(255, 255, 255, 0.9) !important;
    border-radius: 8px !important;
    margin-bottom: 21px;
  }

/* Section 5 PROJECTS */
  .carousel-container{
    position: relative;
    width: 100%;
    height:100vh;
    max-height: 900px;
    padding-top: 40px;
    padding-bottom: 70px;
    margin-bottom: 120px;
    overflow: hidden;
    cursor: grab;
    background-color: transparent;

  }
  .review-title{
    margin-top: 89px;
    padding: 10px;
    font-size: 3.5rem;
    font-weight: 900;
    text-align: center;
    background-color: transparent;
  }
  .review-container{
    position: relative;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 10rem;
    display: flex;
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
    background-color: transparent;

  }
  .review-box{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 750px;
    width: 890px;
    border-radius: 20px;
    padding: 1rem;
    background-color: transparent;
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
    overflow: visible;
    filter: drop-shadow(0px 0px 20px rgb(0, 0, 0));
  }
  .review-box:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: rgb(250, 250, 250, 1);
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
    clip-path: polygon(
            80px 0,
            100% 0,
            100% calc(100% - 80px),
            calc(100% - 80px) 100%,
            0 100%,
            0% 80px);
  }
  .review-box img{
    position: absolute;
    width: 175px;
    height: 175px;
    top: -90px;
    object-fit: cover;
    object-position: center;
    background-color: #a0a0a0;
    border-radius: 100%;
    border: rgb(250, 250, 250, 1) 5px solid;
    filter: grayscale(1);
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    overflow: visible;
    user-select: none;
  }
  .review-box p{
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 680px;
    height: auto;
    padding-top: 1rem;
    margin: 1rem auto;
    vertical-align: center;
    font-size: 1.7rem;
    font-style: italic;
    font-weight: 600;
    text-align: center;
    overflow: visible;
    z-index: 3;
    color: black;
    user-select: none;
  }
  .review-box h1{
    position: absolute;
    bottom: 0;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    margin: 0.89rem;
    color: rgba(0, 0, 0, 0.9);
    user-select: none;
  }
  .review-box h2{
    position: absolute;
    bottom: 0;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    margin: 0.55rem;
    color: rgba(0, 0, 0, 0.8);
    user-select: none;
  }
  .review-stars{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    font-size: 2.5rem;
    color: mediumseagreen;
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
    -webkit-font-smoothing: antialiased;
  }
  .review-container:hover > .review-box{
    transform: scale(1.05);
  }
  .review-container:hover > .review-box img{
    filter: grayscale(0.2);
    transform: scale(1.05);
  }
  .close-button{
    position: absolute;
    font-size: 3rem !important;
    width: 90px;
    height: auto;
    top: 0;
    left: 0;
    color: rgba(255, 255, 255, 0.5);
    padding: 2px;
    margin: 0 auto;
    border: none;
    border-radius:  0 10px 10px 0;
    transition: all 0.5s ease-in;
    background: transparent;
    z-index: 9999 !important;
    overflow: hidden;
  }
  .close-button p{
    color: rgba(255, 255, 255, 1);
    position: relative;
    height: 500px;
    width: 100%;
    font-size: 2.4rem;
    font-weight: 700;
    padding-right: 4px;
    margin: 0 auto 0 1rem;
    text-align: center;
    vertical-align: middle;
    writing-mode: vertical-lr;
    background: transparent;
    border-radius: 0 10px 10px 0;
  }
  .secondary-project-cta{
    width: 550px;
    height: 89px;
    background:var(--bgPrimary-glass);
  }
  .secondary-project-cta p{
    font-size: 2.4rem;
  }

  /* Section CONTACT US */
  .polaris-text{
    font-family: Monoton, Montserrat, serif;;
    font-weight: normal;
    font-size: 2rem;
  }
  .polaris-text-ext{
    font-size: 2rem;
  }
  .subscript-ext{
    top: -8px;
    font-size: 1rem;
  }
  .form-container h2{
    vertical-align: center;
    text-align: center;
    padding: 21px 20px 20px 20px;
    margin: 34px auto 0 auto;
    font-weight: 600;
    width: 750px;
    max-width: 90vw;
    word-break: break-word;
    font-size: 2rem;
    line-height: 1.5;
  }
  .form-container h3{
    vertical-align: center;
    text-align: center;
    padding: 21px 20px 20px 20px;
    margin: 0 auto;
    font-weight: 500;
    width: 1300px;
    max-width: 90vw;
    word-break: break-word;
    font-size: 2.5rem;
    line-height: 1.5;
  }
  .office-hours{
    font-size: 2rem;
  }
  .contact-sub-text{
    font-size: 2rem !important;
    font-weight: 500;
    text-align: center;

  }

  .padding-form-alt{
    padding: 10px !important;
  }
  .row-form{
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto 0;
    padding-top: 13px;
    max-height: 100%;
    max-width: 100vw;
    background-color: transparent;
    overflow: hidden;
  }
  .row-form p{
    position: relative;
    font-size: 2.4rem;
    padding: 21px
  }
  .row-form i{
    position: relative;
    font-size: 3.5rem;
    padding: 21px
  }
  .row-form a{
    margin: 8px;
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
    filter: drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.3));
  }
  .row-form a:hover{
    transform: scale(0.9) translateY(4px);
  }
  .row-form img{
    width: 600px;
    height: auto;
    padding-left: 13px;
    padding-top: 21px;
    margin: 0 auto;
    left: 0;
    right: 0;
    align-content: center;
    align-items: center;
    align-self: center;
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
  }
  .row-form h3{
    position: relative !important;
    font-size: 1.5rem !important;
    font-weight: 400;
    padding: 0 0 0 0;
    margin: 0 34px 89px 0!important;
    text-align: center;
    width: 100%;
    max-width: 100%;
  }
  .form-button i{
    font-size: 2.5rem !important;
  }
  .form-button p{
    font-size: 3rem !important;
  }
  .form-button button{
    display: flex;
    height: 123px;
    width: 90vw;
    max-width: 750px;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
    vertical-align: middle;
    flex-direction: column;
    margin: 1px auto 1px auto !important;
    padding: 2px;
    border-radius: 10px;
    border: 4px solid rgb(245, 245, 245);
    color: rgb(255, 255, 255);
    background-color: rgb(10, 10, 10, 0);
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
  }
  .whatsapp-button{
    background-color: rgb(37, 211, 102) !important;
    border: 4px solid rgb(37, 211, 102) !important;
  }
  .call-button{
    background-color: #006ee6 !important;
    border: 4px solid #006ee6 !important;
  }
  .insta-button{
    border: 4px solid rgb(255, 255, 255) !important;
    background-color: rgba(0, 0, 0, 0.3) !important;
  }
  .qr-code{
    margin: 0 auto;
    left: 0;
    right: 0;
    align-content: center;
    alignment: center;
    padding: 13px;
  }
  .qr-code img{
    width: 340px;
    height: 340px;
    border-radius: 21px;
    border: 4px solid rgba(0, 0, 0, 0.2);
  }

  .contact-form{
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
    width: 95vw;
    max-width: 750px;
    min-height: 600px;
    padding: 0;
    margin: 0;
    color: white;
    background-color: transparent;
    overflow: clip;
  }
  .contact-form label{
    display: flex;
    font-size: 2rem;
    flex-direction: column;
    justify-content: left;
    width: 750px;
    max-width: 95vw;
    height: 100%;
    text-align: center;
    margin: 34px auto 13px auto;
    color: white !important;
    background-color: transparent;
    overflow: hidden;
  }
  .contact-form input{
    display: flex;
    position: relative;
    font-size: 1.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
    width: 720px;
    height: 100%;
    padding: 0;
    margin: 0 auto;
    border-bottom: white 2px solid;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    color: white !important;
    background-color: transparent;
    overflow-x: hidden;
  }

  .contact-form input:focus{
    outline: none;
  }
  .contact-form ::placeholder{
    color: rgba(255, 255, 255, 0.25);
    font-size: 1.5rem;
    text-align: center;
    padding-left: 13px;
  }
  .contact-form input:invalid{
    border-bottom: rgb(255, 255, 255, 0.6) 2px solid;
  }
  .contact-form input:valid{
    border-bottom: rgba(0, 0, 0, 0) 2px solid;
  }
  .contact-form textarea{
    display: flex;
    font-size: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
    width: 750px;
    max-width: 750px;
    height: 60px;
    border: rgba(255, 255, 255, 0) 2px solid;
    color: white !important;
    background-color: var(--bgPrimary-glass);
    user-select: text;
    padding: 1px;
    border-radius: 8px;
    resize: none;
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
  }
  .contact-form textarea:focus{
    height: 240px !important;
    outline: none !important;
    border: none !important;
  }
  .contact-form textarea:hover{
    height: 240px !important;
    outline: none !important;
    border: none !important;
  }
  .contact-form select{
    font-size: 1.5rem;
    width: 750px;
    max-width: 95vw;
    padding: 8px;
    border: none;
    border-radius: 0;
    margin: 13px auto 0 auto;
    color: white !important;
    background-color: var(--bgPrimary-glass);
    overflow: hidden;
    cursor: pointer;
  }
  .contact-form option{
    text-align: center;
    background-color: #0a0a0a !important;
    color: var(--text-primary);
    border: none transparent !important;
  }
  .contact-form input{
    font-size: 1.5rem;
    width: 750px;
    max-width: 750px;
    text-align: center;
    padding: 8px;
    border: none;
    border-radius: 0;
    margin: 0 auto;
    color: white !important;
    background-color: var(--bgPrimary-glass);
    overflow: hidden;
  }

  .submit-form{
    width: 500px;
    max-width: 95vw;
    overflow: hidden;
  }
  .submit-form-button{
    display: inline-block;
    left: 0;
    right: 0;
    justify-content: space-around;
    height: 89px;
    width: 750px;
    max-width: 95vw;
    align-items: center;
    alignment: center;
    align-content: center;
    align-self: center;
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
    flex-direction: row;
    margin: 13px auto 0 auto !important;
    border-radius: 10px;
    background-color: rgb(10, 10, 10, 0);
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    overflow: hidden;
  }
  .invalid-button-border{
    border: 4px solid rgba(245, 245, 245, 0.6) !important;
    color: rgba(255, 255, 255, 0.6) !important;
  }
  .valid-button-border{
    border: 4px solid rgb(245, 245, 245) !important;
    color: rgb(255, 255, 255) !important;
  }

  .submit-form-button:hover{
    color: white !important;
    transform: scale(0.95);
  }

  .submit-form-button-disabled-transform:hover{
    transform: none !important;
  }
  .submit-form-button i{
    display: inline-block;
    opacity: 1;
    font-size: 3rem !important;
    text-align: right;
    padding: 5px 0 0;
    margin-left: 10px;
    vertical-align: middle;
    color: white !important;
    background-color: transparent;
    overflow: hidden;
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
  }
  .submit-form-button:hover i{
    color: white !important;
    transform: translateX(20px);
  }
  .submit-form-button p{
    display: inline-block;
    font-size: 3rem;
    text-align: center;
    vertical-align: middle;
    margin:0 auto;
    color: white !Important;
    background-color: transparent;
    overflow: hidden;
  }
  .submit-policy-text i{
    font-size: 1.5rem;
  }

  .down-arrow-accent-form i{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    color: white;
    background-color: transparent;
    overflow: hidden;
    font-size: 10rem;
  }
  /* Section FOOTER */
  /* CONTACT US */
  .section-contact-us{
    display: flex;
    position: relative;
    min-width: 100vw;
    min-height: 100vh;
    padding: 140px 0 34px 0;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    vertical-align: center;
    background: var(--bgPrimary-glass);
    color: var(--text-primary);
    z-index: 3;
    transition: all 0.5s ease-in;
  }
  .main-flex-tiers {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100vw, 1fr));
    gap: 2rem;
  }
  ol {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .footer-social h2{
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
  }
  .footer-social h2:hover {
    transform: scale(0.95);
  }
  .footer-tier {
    padding: 1rem;
    position: relative;
    display: inline-grid;
    alignment: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    align-items: center;
    font-size: 2.5rem;
  }

  .footer{
    display: flex;
    position: relative;
    min-width: 100vw;
    min-height: 30vh;
    padding: 140px 0 140px 0;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    vertical-align: center;
    background: var(--bgPrimary);
    color: var(--text-primary);
    z-index: 3;
    transition: all 0.5s ease-in;
  }
  .footer h2{
    display: flex;
    position: relative;
    flex-direction: column;
    font-size: 3.5rem;
    text-align: center;
    font-weight: bold;
  }
  .footer p{
    color: rgba(255, 255, 255, 0.8);
    font-size: var(--fontDesktopP);
    padding: 2px;
    font-weight: 500;
    alignment: left;
    margin: 1rem;
    text-align: center;
    word-break: break-word;
  }
  .footer-logo{
    display: flex;
    position: relative;
    width: 600px;
    height: auto;
    padding-left: 42px;
    margin: 10px auto 10px;
    text-align: center;
    alignment: center;
    justify-content: center;
    vertical-align: center;
    flex-direction: column;
    background: var(--bgPrimary);
    color: var(--text-primary);
    z-index: 3;
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
  }
  .footer-logo img{
    width: 500px;
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
  }
  .footer-links{
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
  }
  .footer-social{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
  }
  .footer-links li{
    margin: 0 10px;
    list-style-type: none;
    text-transform: uppercase;
  }
  .footer-social li{
    margin: 0 10px;
    list-style-type: none;
  }
  .footer-links a{
    color: var(--text-primary);
    font-size: 1.5rem;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  .footer-social a{
    color: var(--text-primary);
    font-size: 2.5rem !important;
    text-decoration: none;
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
  }

  .footer-links a:hover{
    transform: scale(0.95);
  }
  .footer-social a:hover{
    transform: scale(0.9);
  }

  .footer-social-buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    margin: 0 auto;
    padding: 0;
    overflow: hidden;
    list-style: none;
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
  }
  .footer-social-buttons a{
    color: var(--text-primary);
    font-size: 6.5rem;
    font-weight: 900;
    padding: 34px;
    margin: 34px;
    text-decoration: none;
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
  }
  .footer-social-buttons i{
    color: var(--text-primary);
    font-size: 6.5rem;
    font-weight: 900;
    padding: 34px;
    margin: 34px;
    text-decoration: none;
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
  }
  .footer-social-buttons a:hover{
    transform: scale(0.9) !important;
  }
  .footer-tier a:hover{
    transform: scale(0.9);
  }
  .studio-pancake-logo-footer img{
    width: 690px !important;
    padding: 2rem;
  }
  /* FLEX BOXES GRIDS */
  .services-container{
    display: flex;
    position: relative;
    max-width: 84vw;
    min-height: 100vh;
    padding: 5px 5px 0 5px;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    vertical-align: center;
    background: transparent;
    color: var(--text-primary);
    z-index: 3;
    transition: all 0.5s ease-in;
  }
  .row {
    margin-top: 60px;
    margin-bottom: 60px;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  /* MISC */
  .pre-loader-icon{
    position: fixed;
    width: 420px;
    top: 44vh;
    animation: breathing var(--preload-anim) forwards linear;
    -webkit-animation: breathing var(--preload-anim) forwards linear;
    padding: 0;
  }

  .aiStyle {
    display: flex;
    position: relative;
    width: 18rem;
    height: 18rem;
    left: -35px;
    margin: -20px;
    overflow: hidden;
  }
  .iconStyle{
    display: flex;
    position: relative;
    width: 12rem;
    height: 12rem;
    left: 5px;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  .iconStyle3{
    display: flex;
    position: relative;
    width: 15rem;
    height: 15rem;
    left: -25px;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  .iconStyle4{
    display: flex;
    position: relative;
    width: 15rem;
    height: 15rem;
    left: -35px;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  .iconStyle2{
    display: flex;
    position: relative;
    width: 12rem;
    height: 12rem;
    left: 8px;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  .bottom-accent-icon{
    position: absolute;
    height: 550px;
    display: flex;
    opacity: 0.8;
    right: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
    background: transparent;
  }
  .no-button-text{
    font-size: 2rem !important;
  }
  .arrow-down-projects{
    left: 0;
    right: 0;
    alignment: center;
    text-align: center;
    color: var(--text-primary);
    font-size: 10rem;
    padding: 13px;
    margin: 8px auto 8px auto;
  }

  .skill-bars .bar .progress-line{
    height: 3rem !important;
  }
}
.skill-bars {
  width: 100%;
  background: transparent !important;
}
.skill-bars .bar {
  margin: 21px 0;
}
.skill-bars .bar:first-child {
  margin-top: 0;
}
.skill-bars .bar .info {
  margin-bottom: 5px;
  color: var(--text-primary);
}
.skill-bars .bar .info {
  font-weight: 500;
  font-size: 2.5rem;
  opacity: 1;
}
.skill-bars .bar .progress-line{
  height: 1.9rem;
  width: 100%;
  background: transparent;
  position: relative;
  transform: scaleX(0);
  transform-origin: left;
  border-radius: 10px;
  animation: animate 0.5s 1s cubic-bezier(1,0,0.5,1) forwards;
  -webkit-animation: animate 0.5s 1s cubic-bezier(1,0,0.5,1) forwards;
}
.bar .progress-line span {
  height: 100%;
  position: absolute;
  border-radius: 5px;
  transform: scaleX(0);
  transform-origin: left;
  background: linear-gradient(
          135deg,
          var(--text-primary) 0%,
          var(--text-primary) 100%
  );
  background-size: 100%;
  -webkit-background-size: 100%;
  animation: animate 0.8s ease-in-out forwards;
  -webkit-animation: animate 0.8s ease-in-out forwards;
}
.bar .progress-line.barOne span{
  width: 80%;
  animation-play-state: paused;
  -webkit-animation-play-state: paused;
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
}
.bar .progress-line.barTwo span {
  width: 88%;
  animation-play-state: paused;
  -webkit-animation-play-state: paused;
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
}
.bar .progress-line.barThree span {
  width: 95%;
  animation-play-state: paused;
  -webkit-animation-play-state: paused;
  animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
}
@keyframes animate {
  0%{transform: scaleX(0)}
  100%{transform: scaleX(1)}
}
@-webkit-keyframes animate {
  0%{transform: scaleX(0)}
  100%{transform: scaleX(1)}
}
@-moz-keyframes animate {
  0%{transform: scaleX(0)}
  100%{transform: scaleX(1)}
}
